import { Navigate, Outlet, useParams } from "react-router-dom";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { useBreakpoint, useContractsSummary, useWindowSize } from "@synota-io/synota-shared-ui";
import { RequiredAddressModal } from "./modals/RequiredAddressModal";
import { useRequiredAddressModal } from "./hooks/useRequiredAddressModal";
import { CONTRACTS_PATH } from "../../paths";
import { LoadingPage } from "../../shared/pages/LoadingPage";
import { Stack, Box, Slide } from "@mui/material";
import { ContractMenu } from "./components/ContractMenu";
import { useLayoutEffect, useMemo, useRef, useState } from "react";

const breadContainerSx = {
  maxWidth: { xs: "100%", sm: "50%" },
  minWidth: { xs: "100%", sm: "50%" },
};

export const ContractsOutlet = () => {
  const size = useWindowSize();
  const headerRef = useRef<HTMLDivElement>(null);
  const breadRef = useRef<HTMLDivElement>(null);
  const requiredAddressModal = useRequiredAddressModal();

  const { contractId } = useParams();
  const { find, isLoading } = useContractsSummary();

  const [width, setWidth] = useState(0);

  const isSm = useBreakpoint("sm");

  useLayoutEffect(() => {
    if (headerRef.current && breadRef.current) {
      const breadcrumb = isSm ? breadRef.current.offsetWidth : 0;
      setWidth(headerRef.current.offsetWidth - breadcrumb);
    }
  }, [isSm, size]);

  const contract = find(contractId);

  const maxWidth = useMemo(() => (isSm ? width : width), [isSm, width]);

  if (contractId && !isLoading && !contract) {
    return <Navigate to={CONTRACTS_PATH} />;
  }

  return (
    <LoadingPage isLoading={Boolean(contractId && !contract)}>
      <Slide in direction="left">
        <Stack
          mb={{ xs: 3, sm: 4 }}
          flexDirection={{ sm: "row" }}
          alignItems="start"
          pb={1}
          ref={headerRef}
        >
          <Box ref={breadRef} sx={breadContainerSx}>
            <Breadcrumbs />
          </Box>
          <Box flexGrow={1} />
          {maxWidth ? (
            <Box key={maxWidth} pl={6} alignSelf={{ sm: "auto", xs: "end" }} mt={{ xs: 4, sm: -2 }}>
              <ContractMenu maxWidth={maxWidth} contract={contract} />
            </Box>
          ) : null}
        </Stack>
      </Slide>
      <Outlet />
      <RequiredAddressModal {...requiredAddressModal} />
    </LoadingPage>
  );
};
