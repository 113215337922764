import { Button, Box, TableContainer } from "@mui/material";
import {
  ButtonRow,
  Form,
  FormSubmit,
  IContractSummary,
  Modal,
  useContractDetails,
  UseModalProps,
} from "@synota-io/synota-shared-ui";
import { ContractTerms } from "../details/ContractTerms";

type Props = UseModalProps & {
  onAgree(): void;
  onDecline(): void;
  contract: IContractSummary;
  isLoading?: boolean;
};

export const ContractInfoModal = ({
  onAgree,
  onDecline,
  contract: contractSummary,
  onClose,
  open,
  isLoading,
}: Props) => {
  const { contract, isLoading: isLoadingContract } = useContractDetails({
    uuid: contractSummary.uuid,
  });

  return (
    <Modal
      isLoading={isLoadingContract}
      open={open}
      onClose={onClose}
      fullWidth
      title={`Sign "${contractSummary.name}"`}
      actions={
        <Form
          sx={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            onAgree();
          }}
        >
          <ButtonRow width="100%">
            <Button
              color="error"
              size="small"
              onClick={() => {
                onDecline();
              }}
            >
              Cancel
            </Button>
            <Box flexGrow={1} display="flex">
              <FormSubmit isLoading={isLoading} fullWidth variant="contained" color="secondary">
                Agree and Sign
              </FormSubmit>
            </Box>
          </ButtonRow>
        </Form>
      }
    >
      <TableContainer>
        <ContractTerms expandRoot contract={contract} />
      </TableContainer>
    </Modal>
  );
};
