import {
  formatDate,
  formatDollarAmount,
  useReportFiltersForm,
  DataGridPage,
  useGridServerPagination,
  BaseDataGrid,
  IPaymentRequest,
  Link,
  useContractsSummary,
  formatEnergyAmount,
  getFormattedEnergyEffectiveRate,
  usePaymentRequests,
  useDefaultError,
} from "@synota-io/synota-shared-ui";
import { useState } from "react";
import { generatePath } from "react-router-dom";
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid";
import { CONTRACT_PATH } from "../../../paths";
import { getPaymentRequestHeaderLabel } from "../../../utils/paymentRequests";
import { ReportFiltersToolbarLinked } from "../../../shared/reports/ReportFiltersToolbarLinked";
import { BackToContractButton } from "../../contracts/components/BackToContractButton";

const defaultDates = {
  startDate: null,
  endDate: null,
};

const ContractCell = ({ row }: Pick<GridRenderCellParams<IPaymentRequest>, "row">) => {
  const { find, isLoading } = useContractsSummary();

  if (isLoading) {
    return null;
  }

  if (row.contractId) {
    const contract = find(row.contractId);

    if (contract) {
      return (
        <Link
          title={contract.name}
          sx={{ textDecoration: "none" }}
          color="text.primary"
          to={generatePath(CONTRACT_PATH, { contractId: row.contractId })}
        >
          {contract.name}
        </Link>
      );
    }

    return row.contractId;
  }

  return "N/A";
};

const columns: GridColDef<IPaymentRequest>[] = [
  {
    field: "contractId",
    headerName: "Contract",
    renderCell: ({ row }) => <ContractCell row={row} />,
    flex: 1,
    width: 220,
    minWidth: 120,
  },
  {
    field: "beginTime",
    type: "number",
    headerName: getPaymentRequestHeaderLabel("settlementStart"),
    valueFormatter: (value) => formatDate(value),
    flex: 1,
    width: 140,
    minWidth: 90,
  },
  {
    field: "energyDeliveredMwh",
    type: "number",
    headerName: getPaymentRequestHeaderLabel("energyDeliveredWithUnit"),
    valueFormatter: (value) => formatEnergyAmount(value),
    flex: 1,
    width: 110,
    minWidth: 90,
  },
  {
    field: "amountInvoiced",
    type: "number",
    headerName: getPaymentRequestHeaderLabel("hostFeeInvoice"),
    valueFormatter: (value) => formatDollarAmount(value),
    flex: 1,
    width: 110,
    minWidth: 90,
  },
  {
    field: "cashReceived",
    type: "number",
    headerName: getPaymentRequestHeaderLabel("customerPayment"),
    valueFormatter: (value) => formatDollarAmount(value),
    flex: 1,
    width: 110,
    minWidth: 90,
  },
  {
    field: "effectiveRateMwh",
    type: "number",
    headerName: getPaymentRequestHeaderLabel("effectiveRateMwh"),
    flex: 1,
    valueFormatter: (_value, row) =>
      getFormattedEnergyEffectiveRate(row.amountInvoiced, row.energyDeliveredMwh),
    width: 110,
    minWidth: 90,
  },
];

export const SettlementsPage = () => {
  const apiRef = useGridApiRef();

  const [paginationModel, onPaginationModelChange] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 20,
  });

  const {
    form: { control },
    values,
    input,
  } = useReportFiltersForm({
    defaultDates,
    report: {
      canFilterContracts: true,
      canFilterCounterparties: true,
    },
  });

  const { paymentRequests, hasNextPage, isFetching, error } = usePaymentRequests({
    ...input,
    include_quotes: true,
    pagination_i: paginationModel.page,
  });

  useDefaultError(error, "There was an error while loading settlements, please try again.");

  const paginationProps = useGridServerPagination({
    hasNextPage,
    currentPageRowCount: paymentRequests.length,
    paginationModel,
    onPaginationModelChange,
  });

  const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>({});

  return (
    <DataGridPage
      title="Settlements"
      recalculateHeightDeps={values}
      actions={<BackToContractButton {...values.contract} />}
      toolbar={<ReportFiltersToolbarLinked control={control} />}
    >
      <BaseDataGrid
        apiRef={apiRef}
        loading={isFetching}
        columns={columns}
        rows={paymentRequests}
        columnVisibilityModel={columnVisibility}
        onColumnVisibilityModelChange={setColumnVisibility}
        {...paginationProps}
      />
    </DataGridPage>
  );
};
