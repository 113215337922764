import { Box, IconButton } from "@mui/material";
import { ProfileMenu } from "../menu/ProfileMenu";
import {
  BitcoinPriceTicker,
  useAccount,
  useLayout,
  SynotaLogoSvg,
} from "@synota-io/synota-shared-ui";
import { Close, Menu } from "@mui/icons-material";

export const TopbarContent = () => {
  const { hasLightningNode, isConsumer } = useAccount();
  const { isSidebarOpen, toggleSidebar, isMobile } = useLayout();

  if (isMobile) {
    return (
      <>
        <IconButton onClick={toggleSidebar}>{isSidebarOpen ? <Close /> : <Menu />}</IconButton>
        <Box flexGrow={1} />
        <SynotaLogoSvg />
        <Box flexGrow={1} />
        <ProfileMenu />
      </>
    );
  }

  return (
    <>
      {isConsumer && hasLightningNode ? <BitcoinPriceTicker /> : null}
      <Box flexGrow={1} />
      <ProfileMenu />
    </>
  );
};
