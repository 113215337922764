import { Outlet } from "react-router-dom";
import { RequiredAddressModal } from "../contracts/modals/RequiredAddressModal";
import { useRequiredAddressModal } from "../contracts/hooks/useRequiredAddressModal";
import { LoadingPage } from "../../shared/pages/LoadingPage";
import { Box } from "@mui/material";

export const ExplorerOutlet = () => {
  const requiredAddressModal = useRequiredAddressModal();

  return (
    <LoadingPage component={Box}>
      <Outlet />
      <RequiredAddressModal {...requiredAddressModal} />
    </LoadingPage>
  );
};
