export const SATOSHIS_IN_BTC = 100_000_000;
export const BTC_IN_SATOSHI = 1 / SATOSHIS_IN_BTC;
export const BTC_FORMAT = "0.00000000";

export const DATE_FORMAT_DEFAULT = "MM/DD/YYYY";
export const DATE_FORMAT_SHORT = "M/D";

export const TIME_FORMAT_DEFAULT = "HH:mm:ss";
export const TIME_FORMAT_SHORT = "h A";

export const DATE_TIME_FORMAT_DEFAULT = "MM/DD/YYYY HH:mm:ss";
