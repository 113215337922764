import {
  BadgeTypography,
  DATE_FORMAT_DEFAULT,
  DetailsList,
  DetailsListItem,
  IAdhocInvoice,
  dayjs,
  formatDollarAmount,
  useAccount,
  useCurrencyConverter,
} from "@synota-io/synota-shared-ui";
import {
  CHARGE_PAYMENT_STATUS_COLOR_MAP,
  CHARGE_PAYMENT_STATUS_ICON_MAP,
  CHARGE_STATUS_COLOR_MAP,
  CHARGE_STATUS_ICON_MAP,
} from "../constants";
import { Stack, Typography } from "@mui/material";
import { ContractCell } from "../cells/ContractCell";

export const AdhocDetailsList = ({ charge }: { charge: IAdhocInvoice }) => {
  const { isSupplier, paymentMethod } = useAccount();
  const { isBluePenguin: isCurrencyConverterBluePenguin } = useCurrencyConverter();
  const isBluePenguin = Boolean(isCurrencyConverterBluePenguin || paymentMethod?.isBluePenguin);
  return (
    <DetailsList dense sx={{ padding: 0 }}>
      <DetailsListItem xs title="Contract">
        <ContractCell row={charge} />
      </DetailsListItem>

      <DetailsListItem xs title="Service day(s)">
        <span>{dayjs(charge.begin_time).format(DATE_FORMAT_DEFAULT)}</span>
        <span> - </span>
        <span>{dayjs(charge.end_time).format(DATE_FORMAT_DEFAULT)}</span>
      </DetailsListItem>

      <DetailsListItem xs title="Title">
        {charge.header_title}
      </DetailsListItem>

      <DetailsListItem xs title="Description">
        {charge.description || "-"}
      </DetailsListItem>

      {isBluePenguin && charge.consumer_third_party_usd_payment_id ? (
        <DetailsListItem xs title="ACH ID">
          {charge.consumer_third_party_usd_payment_id}
        </DetailsListItem>
      ) : null}

      {isSupplier ? (
        <>
          <DetailsListItem xs title="Reference ID/PO No.">
            {charge.reference_id_po_number || "-"}
          </DetailsListItem>
          <DetailsListItem xs title="General Ledger Account Number">
            {charge.general_ledger_account_number || "-"}
          </DetailsListItem>
        </>
      ) : null}

      <DetailsListItem xs title="Amount">
        {charge.amount_in_currency ? formatDollarAmount(charge.amount_in_currency) : "N/A"}
      </DetailsListItem>

      {charge.created_by_user_email ? (
        <DetailsListItem xs title="Created by">
          {charge.created_by_user_email}
        </DetailsListItem>
      ) : null}

      {charge.updated_at && charge.updated_by_user_email ? (
        <DetailsListItem xs title={`${charge.status} by`}>
          {charge.updated_by_user_email || "-"}
        </DetailsListItem>
      ) : null}

      {charge.last_payment_id ? (
        <DetailsListItem xs title="Payment ID">
          {charge.last_payment_id}
        </DetailsListItem>
      ) : null}

      <DetailsListItem xs title="Invoice Status" sx={{ pr: 1 }}>
        <Stack direction="row" py={1}>
          <BadgeTypography
            variant="inherit"
            direction="row-reverse"
            justifyContent="flex-end"
            status={CHARGE_STATUS_COLOR_MAP[charge.status]}
            icon={CHARGE_STATUS_ICON_MAP[charge.status]}
          >
            {charge.status}
          </BadgeTypography>
          {charge.rejection_reason ? (
            <Typography
              mt={-5}
              pr={3}
              textAlign="right"
              flexGrow={1}
              variant="body3"
              component="span"
            >
              <strong>Reason: </strong>
              <span>{charge.rejection_reason}</span>
            </Typography>
          ) : null}
        </Stack>
      </DetailsListItem>
      {charge.detailed_status ? (
        <DetailsListItem xs title="Payment Status" sx={{ pr: 1 }}>
          <Stack direction="row" py={1}>
            <BadgeTypography
              variant="inherit"
              direction="row-reverse"
              justifyContent="flex-end"
              textTransform="capitalize"
              status={CHARGE_PAYMENT_STATUS_COLOR_MAP[charge.detailed_status]}
              icon={CHARGE_PAYMENT_STATUS_ICON_MAP[charge.detailed_status]}
            >
              {charge.detailed_status}
            </BadgeTypography>
            {charge.rejection_reason ? (
              <Typography
                mt={-5}
                pr={3}
                textAlign="right"
                flexGrow={1}
                variant="body3"
                component="span"
              >
                <strong>Reason: </strong>
                <span>{charge.rejection_reason}</span>
              </Typography>
            ) : null}
          </Stack>
        </DetailsListItem>
      ) : null}
    </DetailsList>
  );
};
