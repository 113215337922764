import { Stack, Alert, AlertTitle, CardProps } from "@mui/material";
import { ContractInfoModal } from "../modals/ContractInfoModal";
import { ChevronRight } from "@mui/icons-material";
import {
  BadgeTypography,
  ConfirmationModal,
  FormButton,
  IContractSummary,
  Link,
  useAccount,
  useConfirmationModal,
  useContractsSummary,
  useModal,
  useSignContract,
  useToast,
} from "@synota-io/synota-shared-ui";
import { ContractContainerCard } from "./ContractContainerCard";
import { generatePath, useSearchParams } from "react-router-dom";
import { CONTRACT_PATH } from "../../../paths";
import { useEffect } from "react";

interface Props extends CardProps {
  contract: IContractSummary;
}

export const PendingContractCard = ({ contract, ...props }: Props) => {
  const link = generatePath(CONTRACT_PATH, {
    contractId: contract.uuid,
  });

  return (
    <ContractContainerCard {...props} contract={contract}>
      <Link sx={{ textDecoration: "none" }} to={link}>
        <BadgeTypography variant="h5" maxWidth="24em" status="warning">
          {contract.name}
        </BadgeTypography>
      </Link>

      <Stack
        flexGrow={1}
        direction="row"
        flexWrap="wrap"
        gap={2}
        rowGap={4}
        justifyContent={{ xs: "start", md: "space-between", lg: "space-around" }}
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" width={{ sm: "100%", md: "auto" }}>
          <Alert severity="success">
            <AlertTitle>Contract Terms</AlertTitle>
          </Alert>

          <ChevronRight />
        </Stack>

        <OrderingOfSignOffs contract={contract} />
      </Stack>
    </ContractContainerCard>
  );
};

const OrderingOfSignOffs = ({ contract }: Props) => {
  if (contract.signedBySelf || !contract.signedByCounterparty) {
    return (
      <>
        <SelfSignOffMarking contract={contract} />
        <CounterPartySignOffMarking contract={contract} />
      </>
    );
  }
  return (
    <>
      <CounterPartySignOffMarking contract={contract} />
      <SelfSignOffMarking contract={contract} />
    </>
  );
};

const CounterPartySignOffMarking = ({ contract }: { contract: IContractSummary }) => {
  if (contract.signedByCounterparty) {
    return (
      <Stack direction="row" alignItems="center">
        <Alert severity="success">
          <AlertTitle>Counterparty Approved</AlertTitle>
        </Alert>
        <ChevronRight />
      </Stack>
    );
  }
  return (
    <Stack direction="row" alignItems="center">
      <Alert severity="info">
        <AlertTitle>Pending Counterparty Approval</AlertTitle>
      </Alert>
      <ChevronRight color="disabled" />
    </Stack>
  );
};

const SelfSignOffMarking = ({ contract }: Props) => {
  const { refetch, isFetching: isFetchingContracts } = useContractsSummary();
  const { signContract, isPending } = useSignContract();
  const { jwt, isAdmin } = useAccount();
  const { success } = useToast();
  const modal = useModal({
    onClose: () => {
      setSearch((current) => {
        current.delete("sign");
        return current;
      });
    },
  });
  const confirmationModal = useConfirmationModal();

  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    if (search.get("sign") === contract.uuid && !modal.open) {
      modal.onOpen();
    }
  }, [contract.uuid, modal, search]);

  const selfSignContract = () => {
    if (!isAdmin) {
      return;
    }
    confirmationModal.onOpen({
      title: "You are about to sign:",
      body: contract.name,
      onConfirm() {
        signContract(
          {
            shared_uuid: contract.uuid,
            jwt,
          },
          {
            onSuccess: () => {
              refetch();
              confirmationModal.onClose();
              modal.onClose();
              success("You have successfully signed the contract.");
            },
          },
        );
      },
    });
  };

  return (
    <>
      {contract.signedBySelf ? (
        <Stack direction="row" alignItems="center">
          <Alert severity="success">
            <AlertTitle>Self Approved</AlertTitle>
          </Alert>
          <ChevronRight />
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" flexWrap="wrap" rowGap={3}>
          <Alert severity="info">
            <AlertTitle>Pending Self Approval</AlertTitle>
          </Alert>
          <Stack direction="row" alignItems="center">
            {isAdmin ? (
              <FormButton
                isLoading={isFetchingContracts}
                sx={{ mx: 4, whiteSpace: "nowrap" }}
                variant="contained"
                color="info"
                onClick={() => modal.onOpen()}
              >
                Review to Sign
              </FormButton>
            ) : null}
            <ChevronRight color="disabled" />
          </Stack>
        </Stack>
      )}
      <ConfirmationModal isLoading={isPending} {...confirmationModal} />
      {isAdmin ? (
        <ContractInfoModal
          isLoading={isPending}
          onAgree={() => selfSignContract()}
          onDecline={() => modal.onClose()}
          contract={contract}
          {...modal}
        />
      ) : null}
    </>
  );
};
