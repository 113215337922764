import { Button, Typography } from "@mui/material";
import {
  ButtonRow,
  ConfirmationModal,
  DetailsList,
  DetailsListItem,
  formatDollarAmount,
  FormButton,
  getColDefCell,
  IContractCredit,
  Modal,
  useAccount,
  useConfirmationModal,
  useCreditsMutations,
  useModal,
  UseModalProps,
  useToast,
} from "@synota-io/synota-shared-ui";
import { CreditFormModal } from "./CreditFormModal";
import { DeleteForever, Edit } from "@mui/icons-material";
import { creditsColDef } from "../pages/CreditsPage";

type Props = UseModalProps & {
  credit: IContractCredit | null;
  onMutationSuccess: () => void;
};

export const CreditDetailModal = ({ credit, open, onClose, onMutationSuccess }: Props) => {
  const { isAdmin, isSupplier, jwt } = useAccount();
  const { deleteCredit, isDeleteCreditPending } = useCreditsMutations();

  const confirmationModal = useConfirmationModal();
  const editCreditModal = useModal();
  const toast = useToast();

  if (!credit) {
    return null;
  }

  return (
    <Modal
      fullWidth
      title="Credit"
      open={open}
      onClose={onClose}
      actions={
        <ButtonRow flexGrow={1} px={4} pb={2}>
          <Button color="error" size="small" onClick={onClose}>
            Close
          </Button>
          {isAdmin && isSupplier ? (
            <Button
              onClick={() => {
                editCreditModal.onOpen();
              }}
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              startIcon={<Edit />}
            >
              Edit
            </Button>
          ) : null}
          {isAdmin && isSupplier && credit.amount === credit.amount_remaining ? (
            <FormButton
              color="error"
              variant="contained"
              size="small"
              fullWidth
              startIcon={<DeleteForever />}
              isLoading={isDeleteCreditPending}
              onClick={() =>
                confirmationModal.onOpen({
                  acceptLabel: "Delete",
                  title: `Delete credit for ${formatDollarAmount(Number(credit.amount))}?`,
                  onConfirm: () => {
                    confirmationModal.onClose();
                    deleteCredit(
                      {
                        jwt,
                        credit_shared_uuid: credit.shared_uuid,
                      },
                      {
                        onSuccess: () => {
                          onMutationSuccess();
                          onClose();
                          toast.success("Credit deleted successfully.");
                        },
                      },
                    );
                  },
                })
              }
            >
              Delete
            </FormButton>
          ) : null}
        </ButtonRow>
      }
    >
      <DetailsList disablePadding dense>
        {creditsColDef.map((column) => {
          const value = getColDefCell(column, credit);
          if (!value) {
            return null;
          }
          return (
            <DetailsListItem xs key={column.field} title={column.headerName || column.field}>
              <div>{value}</div>
              {column.description ? (
                <Typography color="text.disabled" variant="subtitle4">
                  ({column.description})
                </Typography>
              ) : null}
            </DetailsListItem>
          );
        })}
      </DetailsList>
      {editCreditModal.open && (
        <CreditFormModal {...editCreditModal} credit={credit} onSuccess={onMutationSuccess} />
      )}
      <ConfirmationModal {...confirmationModal} />
    </Modal>
  );
};
