import {
  ConfirmationModal,
  CreateAdhocInvoiceInput,
  DATE_FORMAT_DEFAULT,
  DetailsList,
  DetailsListItem,
  FormStep,
  UseConfirmationModalProps,
  dayjs,
  formatDollarAmount,
} from "@synota-io/synota-shared-ui";

export interface ConfirmAdhocState {
  charge: Pick<
    CreateAdhocInvoiceInput,
    | "amount"
    | "begin_time"
    | "end_time"
    | "description"
    | "contract_shared_uuid"
    | "general_ledger_account_number"
    | "header_title"
    | "reference_id_po_number"
  >;
  contract: { name?: string | undefined } | null | undefined;
}

export const ConfirmAdhocModal = ({ ...props }: UseConfirmationModalProps<ConfirmAdhocState>) => {
  const {
    state: { contract, charge },
  } = props;

  if (!contract) {
    return null;
  }

  return (
    <ConfirmationModal {...props}>
      <FormStep>
        <DetailsList dense sx={{ padding: 0 }}>
          <DetailsListItem xs title="Contract">
            {contract.name}
          </DetailsListItem>
          <DetailsListItem xs title="Period">
            <span>{dayjs(charge.begin_time).format(DATE_FORMAT_DEFAULT)}</span>
            <span> - </span>
            <span>{dayjs(charge.end_time).format(DATE_FORMAT_DEFAULT)}</span>
          </DetailsListItem>
          <DetailsListItem xs title="Title">
            {charge.header_title}
          </DetailsListItem>
          <DetailsListItem xs title="Description">
            {charge.description || "-"}
          </DetailsListItem>
          <DetailsListItem xs title="Reference ID/PO No.">
            {charge.reference_id_po_number || "-"}
          </DetailsListItem>
          <DetailsListItem xs title="General Ledger Account Number">
            {charge.general_ledger_account_number || "-"}
          </DetailsListItem>
          <DetailsListItem xs title="Amount">
            {charge.amount ? formatDollarAmount(charge.amount) : "N/A"}
          </DetailsListItem>
        </DetailsList>
      </FormStep>
    </ConfirmationModal>
  );
};
