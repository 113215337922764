import { DeleteForever, Edit, EditNote, MoreVert } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  IconButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { forwardRef, MouseEvent, ReactNode, Ref, useCallback, useMemo, useState } from "react";
import { EditContractModal } from "../modals/EditContractModal";
import {
  ConfirmationModal,
  IContractSummary,
  useAccount,
  useConfirmationModal,
  useContractsSummary,
  useDeleteContract,
  useModal,
  useToast,
} from "@synota-io/synota-shared-ui";
import { EditContractCounterpartyModal } from "../modals/EditContractCounterpartyModal";
import { ContractAction } from "../hooks/useContractActions";
import { useLocation } from "react-router-dom";

export const ContractMoreMenu = forwardRef(function ContractMoreMenu(
  {
    contract,
    actions,
    elements: inElements = [],
    ...props
  }: {
    contract: IContractSummary;
    elements?: Array<{ action: string; element: ReactNode } | null>;
    actions: Array<ContractAction | string>;
  } & IconButtonProps,
  ref: Ref<HTMLDivElement>,
) {
  const { refetch } = useContractsSummary();
  const { deleteContract, isPending } = useDeleteContract({ onSuccess: refetch });
  const { pathname } = useLocation();
  const { success } = useToast();
  const { jwt, isAdmin } = useAccount();

  const editModal = useModal();
  const counterpartyModal = useModal();
  const deleteModal = useConfirmationModal();

  const onDeleteClick = useCallback(() => {
    deleteModal.onOpen({
      title: "Delete contract?",
      body: contract.name,
      onConfirm() {
        deleteContract(
          {
            jwt,
            contract_id: contract.id,
          },
          {
            onSuccess() {
              deleteModal.onClose();
              success("Contract deleted successfully.");
            },
          },
        );
      },
    });
  }, [contract, deleteContract, deleteModal, jwt, success]);

  const [contractMenuAnchor, setContractMenuAnchor] = useState<null | HTMLElement>(null);

  const onOpenContractMenu = (event: MouseEvent<HTMLElement>) => {
    setContractMenuAnchor(event.currentTarget);
  };

  const onCloseContractMenu = () => {
    setContractMenuAnchor(null);
  };

  const onContractMenuItemClick = useCallback((cb: () => void) => {
    setContractMenuAnchor(null);
    cb();
  }, []);

  const elements = useMemo(() => {
    const elements: Record<ContractAction | string, ReactNode> = {
      delete: (
        <MenuItem key="delete" onClick={() => onContractMenuItemClick(onDeleteClick)}>
          <ListItemIcon>
            <DeleteForever color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete Contract</ListItemText>
        </MenuItem>
      ),
      renameCounterparty: (
        <MenuItem
          key="renameCounterparty"
          onClick={() => onContractMenuItemClick(counterpartyModal.onOpen)}
        >
          <ListItemIcon>
            <EditNote fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename Counterparty</ListItemText>
        </MenuItem>
      ),
      rename: (
        <MenuItem key="rename" onClick={() => onContractMenuItemClick(editModal.onOpen)}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename Contract</ListItemText>
        </MenuItem>
      ),
    };

    if (inElements.length) {
      elements["divider"] = <Divider key="divider" />;
    }

    for (const inElement of inElements) {
      if (!inElement) {
        continue;
      }
      const { action, element } = inElement;
      elements[action] = element;
    }

    return elements;
  }, [counterpartyModal, editModal, inElements, onContractMenuItemClick, onDeleteClick]);

  if (!isAdmin) {
    return null;
  }

  const allActions = actions.concat(
    inElements.length ? ["divider", ...inElements.map((e) => (e ? e.action : ""))] : [],
  );

  return (
    <div
      key={pathname}
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconButton {...props} onClick={onOpenContractMenu}>
        {props.children || <MoreVert />}
      </IconButton>
      <Menu
        anchorEl={contractMenuAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(contractMenuAnchor)}
        onClose={onCloseContractMenu}
      >
        {Object.entries(elements)
          .reverse()
          .filter(([key]) => allActions.includes(key as ContractAction))
          .map(([, element]) => element)}
      </Menu>

      {editModal.open ? (
        <EditContractModal {...editModal} onSuccess={refetch} contract={contract} />
      ) : null}

      {counterpartyModal.open ? (
        <EditContractCounterpartyModal
          {...counterpartyModal}
          onSuccess={refetch}
          contract={contract}
        />
      ) : null}

      <ConfirmationModal isLoading={isPending} {...deleteModal} />
    </div>
  );
});
