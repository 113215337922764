import { BadgeTypography, IUserGroupUser, UserRole } from "@synota-io/synota-shared-ui";

const USER_ROLE_COLOR_MAP: Record<UserRole, "success" | "warning"> = {
  admin: "success",
  backup_admin: "success",
  pending_admin: "warning",
  pending_admin_needs_tos: "success",
  pending_backup_admin: "warning",
  pending_readonly: "warning",
  readonly: "success",
};

export const UserStatusCell = ({ row }: { row: IUserGroupUser }) => {
  return (
    <BadgeTypography
      width="auto"
      variant="subtitle4"
      status={row.disabled ? "error" : USER_ROLE_COLOR_MAP[row.role]}
    >
      {row.disabled ? "Disabled" : row.status}
    </BadgeTypography>
  );
};
