import { ContractDescription, IContract, formatDate } from "@synota-io/synota-shared-ui";
import { CONTRACT_FIELDS_CUSTOM_HACK } from "../../../utils/environment";

export type ContractCustomField = {
  value: string;
  label: string;
};

export const useContractCustomDescriptions = (contract?: IContract | null): ContractDescription => {
  if (!contract || !contract.uuid) {
    return { label: null, descriptions: [], subsections: [] };
  }

  const { name, beginDate, endDate, description, settlementDetails, counterparty } = contract || {};

  const COMMON_FIELDS = [
    {
      label: "Contract Name",
      value: name,
    },
    {
      label: "Counterparty",
      value: counterparty,
    },
    {
      label: "Contract Details",
      value: description,
    },
    {
      label: "Start Date",
      value: formatDate(beginDate) || "",
    },
    {
      label: "End Date",
      value: formatDate(endDate) || "",
    },
    {
      label: "Settlement Window / Energy Day",
      value: settlementDetails,
    },
  ];

  const hackArray: ContractCustomField[] = [];

  if (CONTRACT_FIELDS_CUSTOM_HACK) {
    const contractItems = (CONTRACT_FIELDS_CUSTOM_HACK as string).split("===");

    for (let i = 0; i < contractItems.length; i++) {
      const contractHack = contractItems[i];

      if (contractHack.split("~~")[0] === contract.uuid) {
        const contractLabels = contractHack.split("~~");

        for (let index = 1; index < contractLabels.length; index++) {
          // note start at 1, after the contract uuid
          const pairing = contractLabels[index];

          const [label, value] = pairing.split(":::");

          hackArray.push({
            label,
            value,
          });
        }
      }
    }
  }

  if (hackArray.length) {
    return { label: null, descriptions: hackArray, subsections: [] };
  }

  if (contract.customDescriptions) {
    return contract.customDescriptions;
  }

  return { label: null, descriptions: COMMON_FIELDS, subsections: [] };
};
