import {
  BreadcrumbsProps,
  Breadcrumbs as MuiBreadcrumbs,
  Slide,
  Typography,
  TypographyProps,
} from "@mui/material";
import { generatePath, useMatch } from "react-router-dom";
import { AppMenuItemProps } from "../../../shared/menu/types";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";
import { Link, LinkProps } from "@synota-io/synota-shared-ui";
import { forwardRef, Ref } from "react";

export interface BreadLinkProps extends AppMenuItemProps {
  title: string;
}

export const Breadcrumbs = forwardRef(function Breadcrumbs(
  props: BreadcrumbsProps,
  ref: Ref<HTMLDivElement>,
) {
  const { matchedLinks } = useBreadcrumbs();

  if (!matchedLinks.length) {
    return null;
  }

  return (
    <MuiBreadcrumbs ref={ref} {...props} sx={{ ...props.sx }}>
      {matchedLinks.map((link) => (
        <Bread key={link.title} {...link} />
      ))}
    </MuiBreadcrumbs>
  );
});

const Bread = ({
  to,
  path = "#",
  params = {},
  ...props
}: Omit<LinkProps, "to"> & AppMenuItemProps) => {
  const isActive = Boolean(useMatch({ path, end: true }));
  const activeProps: TypographyProps<any> = isActive
    ? { variant: "h5" }
    : { variant: "h6", component: Link, underline: "none", to: to || generatePath(path, params) };

  return (
    <Typography
      fontSize={{ xs: "small", sm: "initial" }}
      color="text.primary"
      {...activeProps}
      {...props}
    >
      {isActive ? (
        <Slide in direction="right">
          <span>{props.title}</span>
        </Slide>
      ) : (
        props.title
      )}
    </Typography>
  );
};
