import { Paper } from "@mui/material";
import { useTreeViewApiRef } from "@mui/x-tree-view/hooks/useTreeViewApiRef";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { useMemo, useRef, useState } from "react";
import {
  ContractDescription,
  DetailsTreeItem,
  IContract,
  DetailsTree,
} from "@synota-io/synota-shared-ui";
import { useContractCustomDescriptions } from "../hooks/useContractCustomFields";

interface DetailsTreeItemValue extends TreeViewBaseItem {
  value?: string;
}

function contractDescriptionsToTreeView(
  item: ContractDescription | undefined,
  id: string = "root",
  label: string = "Contract Terms",
): DetailsTreeItemValue {
  if (!item) {
    return {
      id: "root",
      label: "Contract Terms",
      children: [],
    };
  }

  const newId = item.label ? item.label + id : id;
  return {
    id: newId,
    label: item.label || label,
    children: [
      ...(item.descriptions || []).map((description, i) => ({
        id: newId + description.label + i.toString(),
        ...description,
      })),
      ...(item.subsections || []).map((subsection, i) =>
        contractDescriptionsToTreeView(subsection, newId + i.toString()),
      ),
    ],
  };
}
export const ContractTerms = ({
  contract,
  expandRoot,
}: {
  contract?: IContract | null;
  expandRoot?: boolean;
}) => {
  const terms = useContractCustomDescriptions(contract);

  const items: DetailsTreeItemValue[] = useMemo(
    () => [contractDescriptionsToTreeView(terms, "root", "Contract Terms")],
    [terms],
  );

  const apiRef = useTreeViewApiRef();
  const paperRef = useRef<HTMLDivElement>(null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  if (!terms.descriptions.length) {
    return null;
  }

  return (
    <Paper tabIndex={-1} ref={paperRef} elevation={0}>
      <DetailsTree
        apiRef={apiRef}
        items={items}
        slots={{ item: DetailsTreeItem }}
        defaultExpandedItems={expandRoot ? [items[0].id] : []}
        selectedItems={selectedItem}
        onSelectedItemsChange={(_event, itemId) => {
          if (itemId === items[0].id) {
            paperRef.current?.focus();
            return;
          }

          setSelectedItem(itemId);
        }}
      />
    </Paper>
  );
};
