import { Chip, SvgIconTypeMap, Typography, Stack } from "@mui/material";
import { IUserGroupUser, UserRole } from "@synota-io/synota-shared-ui";
import { AccessTime, Person, VerifiedUser } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

const USER_ROLE_ICON_MAP: Record<UserRole, OverridableComponent<SvgIconTypeMap>> = {
  admin: VerifiedUser,
  backup_admin: VerifiedUser,
  pending_admin: AccessTime,
  pending_admin_needs_tos: VerifiedUser,
  pending_backup_admin: AccessTime,
  pending_readonly: AccessTime,
  readonly: Person,
};

export const USER_ROLE_USER_FRIENDLY_NAME: Record<UserRole, "Admin" | "Read-only"> = {
  admin: "Admin",
  backup_admin: "Admin",
  pending_admin: "Admin",
  pending_admin_needs_tos: "Admin",
  pending_backup_admin: "Admin",
  pending_readonly: "Read-only",
  readonly: "Read-only",
};

export const UserRoleCell = ({ row }: { row: IUserGroupUser }) => {
  const Icon = USER_ROLE_ICON_MAP[row.role];
  return (
    <Typography
      variant="subtitle4"
      component={Stack}
      direction="row"
      alignItems="center"
      height="95%"
    >
      <Chip
        color={!row.disabled && row.status === "Active" ? "success" : "default"}
        size="small"
        label={USER_ROLE_USER_FRIENDLY_NAME[row.role]}
        icon={<Icon fontSize="small" />}
      />
    </Typography>
  );
};
