import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Typography,
} from "@mui/material";

import { StrikeFeatureForm } from "./StrikeFeatureForm";
import {
  Announcement,
  DetailsList,
  DetailsListItem,
  EStrikeOAuthFeature,
  Link,
  useAccount,
  useCurrentUsdBalance,
} from "@synota-io/synota-shared-ui";

const CONSUMER_STRIKE_PERMISSIONS = [
  EStrikeOAuthFeature.AutomaticPayout,
  EStrikeOAuthFeature.FromStrikePayments,
  EStrikeOAuthFeature.GetBalance,
  EStrikeOAuthFeature.AchPayments,
  EStrikeOAuthFeature.OnChainDeposits,
];

export const ConsumerPaymentMethodForm = () => {
  const { paymentMethod } = useAccount();

  const { description } = useCurrentUsdBalance();

  if (!paymentMethod) {
    return (
      <Box p={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack spacing={6}>
      <Typography variant="h6">Payment Method</Typography>
      {paymentMethod.isStrike ? (
        <DetailsList>
          <StrikeFeatureForm features={CONSUMER_STRIKE_PERMISSIONS} />
          {description && <DetailsListItem title="Strike Account">{description}</DetailsListItem>}
        </DetailsList>
      ) : null}
      {paymentMethod.isBluePenguin ? (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Entity Name</TableCell>
                <TableCell>{paymentMethod.details?.BluePenguin?.customer_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Customer ID</TableCell>
                <TableCell>{paymentMethod.details?.BluePenguin?.customer_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Routing Number</TableCell>
                <TableCell>{paymentMethod.details?.BluePenguin?.routing_number}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Account Number</TableCell>
                <TableCell>{paymentMethod.details?.BluePenguin?.account_number}</TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  <Stack direction="row" pt={4}>
                    <Announcement>
                      To update banking information, please email{" "}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        color="inherit"
                        href="mailto:support@synota.io"
                      >
                        support@synota.io
                      </Link>{" "}
                      to receive an ACH Account Authorization form.
                    </Announcement>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : null}
    </Stack>
  );
};
