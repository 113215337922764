import {
  Card,
  CardContent,
  CardProps,
  Slide,
  Stack,
  Typography,
  CardActionArea,
} from "@mui/material";
import {
  IContractSummary,
  StatsBox,
  dayjs,
  formatDollarAmount,
  formatDateTime,
  useAccount,
  useStatementData,
} from "@synota-io/synota-shared-ui";
import { PropsWithChildren } from "react";
import { useContractActions } from "../hooks/useContractActions";
import { ContractMoreMenu } from "./ContractMoreMenu";
import { useNavigate } from "react-router-dom";

export type ContractContainerCardProps = PropsWithChildren<
  CardProps & { contract: IContractSummary | null; link?: string }
>;

export const ContractContainerCard = ({
  children,
  contract,
  link,
  ...props
}: ContractContainerCardProps) => {
  const contractActions = useContractActions({ contract });

  const { isSupplier } = useAccount();

  const navigate = useNavigate();
  const defaultInput = {
    startOfMonthTime: dayjs().startOf("month").toISOString(),
    endOfToday: dayjs().endOf("day").toISOString(),
  };

  const { statement } = useStatementData(
    defaultInput.startOfMonthTime,
    defaultInput.endOfToday,
    contract?.uuid,
  );

  if (!contract) {
    return null;
  }

  const outstandingAmount = Number(statement?.currentAmountOutstanding) || 0;

  const actionAreaProps = link
    ? {
        component: CardActionArea,
        disableTouchRipple: true,
        disableRipple: true,
        onClick: (e: React.MouseEvent) => {
          if (!e.isDefaultPrevented()) {
            navigate(link);
          }
        },
      }
    : {};

  return (
    <Slide direction="left" in>
      <Card {...actionAreaProps} {...props}>
        <CardContent sx={{ minHeight: "6em", display: "flex", alignItems: "center" }}>
          <Stack
            px={2}
            rowGap={4}
            direction="row"
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems={{ xs: "space-between", sm: "center" }}
            height="100%"
            width="100%"
            flexWrap={{ xs: link ? "wrap" : "nowrap", sm: "nowrap" }}
          >
            <Stack
              flexGrow={50}
              direction="row"
              flexWrap="wrap"
              gap={2}
              rowGap={4}
              justifyContent="space-between"
              alignItems="center"
            >
              {children}
            </Stack>
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="center"
              alignSelf="start"
              flexGrow={1}
              flexShrink={0}
            >
              {link ? (
                <StatsBox
                  sx={{ ml: 4, whiteSpace: "nowrap", flexGrow: 1 }}
                  onClick={() => navigate(link)}
                  variant={outstandingAmount > 0 ? "primary" : "neutral"}
                  fontSize="small"
                  items={[
                    <Stack minWidth={{ xs: "auto", md: "228px" }} key="ar" direction="row" gap={2}>
                      <Typography variant="inherit" color="text.secondary">
                        Current {isSupplier ? "A/R" : "A/P"}:
                      </Typography>
                      <Typography variant="inherit">
                        {formatDollarAmount(outstandingAmount)}
                      </Typography>
                    </Stack>,
                    <Stack key="credit" direction="row" gap={2}>
                      <Typography variant="inherit" color="text.secondary">
                        Credit Balance:
                      </Typography>
                      <Typography variant="inherit">
                        {formatDollarAmount(Number(contract.creditBalance))}
                      </Typography>
                    </Stack>,

                    <Stack key="lastInvoice" direction="row" gap={2}>
                      <Typography variant="inherit" color="text.secondary">
                        Last Invoice:
                      </Typography>
                      <Typography variant="inherit">
                        {statement?.lastInvoiceTime
                          ? `${formatDateTime(statement.lastInvoiceTime)} UTC`
                          : "None"}
                      </Typography>
                    </Stack>,
                  ]}
                />
              ) : null}
              {contractActions.length ? (
                <ContractMoreMenu contract={contract} actions={contractActions} />
              ) : null}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Slide>
  );
};
