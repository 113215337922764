import { GridRenderCellParams } from "@mui/x-data-grid";
import { BadgeTypography, IAdhocInvoice } from "@synota-io/synota-shared-ui";
import { CHARGE_PAYMENT_STATUS_COLOR_MAP } from "../constants";

export const PaymentStatusCell = ({ row }: Pick<GridRenderCellParams<IAdhocInvoice>, "row">) => {
  return row.detailed_status ? (
    <BadgeTypography
      variant="subtitle3"
      sx={{ textTransform: "capitalize" }}
      status={CHARGE_PAYMENT_STATUS_COLOR_MAP[row.detailed_status]}
    >
      {row.detailed_status}
    </BadgeTypography>
  ) : null;
};
