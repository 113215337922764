import { Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { IAdhocInvoice, useAccount } from "@synota-io/synota-shared-ui";

type Props = {
  onDetailsClick: React.MouseEventHandler<HTMLButtonElement> | (() => void);
};

export const ActionsCell = ({
  row,
  onDetailsClick,
}: Pick<GridRenderCellParams<IAdhocInvoice>, "row"> & Props) => {
  const { isAdmin } = useAccount();
  const needsReview = isAdmin && !row.consumer_verified && !row.void && !row.rejected;
  return (
    <Button
      size="small"
      sx={{ padding: 0 }}
      color={needsReview ? "warning" : "primary"}
      variant="contained"
      onClick={onDetailsClick}
    >
      {needsReview ? "Review" : "Details"}
    </Button>
  );
};
