import { alpha, collapseClasses, IconButton, Stack, styled, Typography } from "@mui/material";
import { useTreeItem2Utils } from "@mui/x-tree-view/hooks/useTreeItem2Utils/useTreeItem2Utils";
import { treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { TreeItem2, TreeItem2Props } from "@mui/x-tree-view/TreeItem2";
import React, { forwardRef, useState } from "react";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { formatDateTime, IDocument, TextButton } from "@synota-io/synota-shared-ui";
import { DeleteForever, Description, Download, OpenInNew, PostAdd } from "@mui/icons-material";
import { generatePath, Link } from "react-router-dom";
import { CONTRACT_PATH } from "../../../paths";

export interface DocumentsTreeLabelProps {
  id: string;
  children: string;
  className: string;
  childCount?: number;
  document?: IDocument;
  isLoading?: boolean;
  onUploadClick?: React.MouseEventHandler<HTMLElement>;
  onDropFile?: React.DragEventHandler<HTMLElement>;
  onDownloadClick?: React.MouseEventHandler<HTMLElement>;
  onDeleteClick?: React.MouseEventHandler<HTMLElement>;
}

export type DocumentsTreeItemValue = TreeViewBaseItem<{
  id: string;
  label: string;
  disabled?: boolean;
  document?: IDocument;
  children?: DocumentsTreeItemValue[];
  onUploadClick?: React.MouseEventHandler<HTMLElement>;
  onDropFile?: React.DragEventHandler<HTMLElement>;
  onDownloadClick?: React.MouseEventHandler<HTMLElement>;
  onDeleteClick?: React.MouseEventHandler<HTMLElement>;
}>;

export function DocumentsTreeLabel({
  children,
  childCount,
  className,
  document,
  id,
  isLoading,
  onUploadClick,
  onDropFile,
  onDeleteClick,
  onDownloadClick,
}: DocumentsTreeLabelProps) {
  const [isHover, setHover] = useState(false);

  if (document) {
    const { created_at, is_mine } = document;
    return (
      <Stack
        title={children}
        className={className}
        gap={2}
        direction="row"
        alignItems="center"
        width="100%"
      >
        <Description fontSize="medium" />
        <div>
          <Typography
            maxWidth="50vw"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            component="div"
            variant="subtitle1"
            fontWeight={600}
          >
            {children}
          </Typography>
          <Typography component="div" variant="subtitle4" color="text.disabled">
            {formatDateTime(created_at)}
          </Typography>
        </div>
        <Stack flexGrow={1} />
        {is_mine && onDeleteClick && (
          <IconButton disabled={isLoading} onClick={onDeleteClick} title="Delete" color="error">
            <DeleteForever fontSize="small" />
          </IconButton>
        )}
        {onDownloadClick && (
          <IconButton disabled={isLoading} onClick={onDownloadClick} title="Download">
            <Download fontSize="small" />
          </IconButton>
        )}
      </Stack>
    );
  }

  if (childCount === undefined) {
    return (
      <Stack py={1} width="100%" role="button" onClick={onUploadClick}>
        <Typography variant="subtitle1" fontWeight={600} color="text.disabled">
          {children}
        </Typography>
        <TextButton color="primary.dark">Click to Upload</TextButton>
      </Stack>
    );
  }

  const onDrop: React.DragEventHandler<HTMLDivElement> = (e) => {
    setHover(false);
    e.preventDefault();

    onDropFile?.(e);
  };

  const onDragOver: React.DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
  };

  const onDragEnter: React.DragEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setHover(true);
  };

  const onDragLeave: React.DragEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setHover(false);
  };

  const hoverTitle = `Upload to ${children}`;

  return (
    <Stack
      className={className}
      gap={2}
      direction="row"
      alignItems="center"
      width="100%"
      sx={
        isHover
          ? (theme) => ({
              paddingLeft: theme.spacing(1),
              borderRadius: theme.spacing(1),
              backgroundColor: alpha(theme.palette.primary.main, 0.25),
              [`& *`]: {
                pointerEvents: "none",
              },
            })
          : undefined
      }
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <Typography
        sx={{ flexGrow: 1 }}
        title={isHover ? hoverTitle : children}
        component="div"
        variant="body1"
        fontSize="medium"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        className={className}
      >
        {isHover ? hoverTitle : children}
      </Typography>
      <Stack flexGrow={1} />
      <IconButton
        title="Go to Contract"
        component={Link}
        to={generatePath(CONTRACT_PATH, { contractId: id })}
      >
        <OpenInNew fontSize="small" />
      </IconButton>

      <Typography
        title={`${childCount} file${childCount !== 1 ? "s" : ""}`}
        px={2}
        component="div"
        variant="body1"
        fontSize="medium"
        color="text.secondary"
      >
        ({childCount})
      </Typography>
      <IconButton
        disabled={isLoading}
        onClick={onUploadClick}
        title="Upload document to this contract"
        color={isHover ? "success" : "primary"}
      >
        <PostAdd fontSize="small" />
      </IconButton>
    </Stack>
  );
}

export type DocumentsTreeItemProps = TreeItem2Props & { isLoading?: boolean };

export const DocumentsTreeItem = styled(
  forwardRef(function DetailsTreeItem(
    { isLoading, ...props }: DocumentsTreeItemProps,
    ref: React.Ref<HTMLLIElement>,
  ) {
    const { publicAPI } = useTreeItem2Utils({
      itemId: props.itemId,
      children: props.children,
    });

    const item: DocumentsTreeItemValue = publicAPI.getItem(props.itemId);

    return (
      <TreeItem2
        {...props}
        ref={ref}
        onDoubleClick={item.onDownloadClick}
        disabled={item.disabled}
        slots={{
          label: DocumentsTreeLabel,
        }}
        slotProps={{
          root: {
            className: item.document ? "isDocument" : "isSection",
          },
          label: {
            isLoading,
            id: item.id,
            document: item.document,
            childCount: item.children?.filter((c: DocumentsTreeItemValue) => c.document).length,
            onUploadClick: item.onUploadClick,
            onDownloadClick: item.onDownloadClick,
            onDeleteClick: item.onDeleteClick,
            onDropFile: item.onDropFile,
          } as DocumentsTreeLabelProps,
        }}
      />
    );
  }),
)(({ theme }) => ({
  [`&.isSection`]: {
    marginBottom: theme.spacing(2),
  },
  [`&.isDocument .${treeItemClasses.content} .${treeItemClasses.iconContainer}`]: {
    width: 0,
  },
  [`&.isDocument .${treeItemClasses.content}`]: {
    padding: theme.spacing(1, 0),
  },
  [`& .${collapseClasses.wrapper}`]: {
    padding: theme.spacing(1, 0, 1, 4),
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.3)}`,
  },
  [`&.isSection .${treeItemClasses.iconContainer}`]: {
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.dark,
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    ...theme.applyStyles("light", {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    }),
    ...theme.applyStyles("dark", {
      color: theme.palette.primary.contrastText,
    }),
  },
  color: theme.palette.text.primary,
}));
