import { Box } from "@mui/material";
import {
  ButtonRow,
  CreateAdhocInvoiceInput,
  DateField,
  Dayjs,
  Form,
  FormButton,
  FormStep,
  FormSubmit,
  InputField,
  Modal,
  NumericField,
  dayjs,
  isDayjs,
  useAccount,
  useAdhocInvoicesMutations,
  ContractSearchField,
  useConfirmationModal,
  useToast,
  ModalProps,
} from "@synota-io/synota-shared-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { useState } from "react";
import { ConfirmAdhocModal, ConfirmAdhocState } from "./ConfirmAdhocModal";
import { CreateAnotherField } from "./CreateAnotherField";

export interface AdhocInvoiceFormValues {
  createAnother?: boolean;
  contract: { uuid: string; name: string } | null;
  usdAmount: number;
  description: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  title: string;
  accountNumber: string;
  referenceId: string;
}

export const createInvoiceSchema = yup.object({
  createAnother: yup.bool(),
  contract: yup
    .object({
      uuid: yup.string().required("Invalid contract"),
      name: yup.string(),
    })
    .required("Contract is required"),
  title: yup.string().required("A title is required"),
  accountNumber: yup.string(),
  description: yup.string(),
  startDate: yup.object().required("Period start is required"),
  endDate: yup.object().required("Period end is required"),
  referenceId: yup.string(),
  usdAmount: yup
    .number()
    .typeError("USD Amount is required")
    .min(0, "USD Amount is required")
    .required("USD Amount is required"),
});

export const CreateAdhocFormModal = ({
  open,
  onClose,
  onSuccess,
  ...props
}: ModalProps & { onSuccess: (values: CreateAdhocInvoiceInput) => void }) => {
  const toast = useToast();
  const { jwt } = useAccount();
  const { isPending, createCharge } = useAdhocInvoicesMutations();
  const [hasSetEndDate, setHasSetEndDate] = useState(false);

  const { control, handleSubmit, setValue, trigger, reset, formState } =
    useForm<AdhocInvoiceFormValues>({
      resolver: yupResolver(createInvoiceSchema) as any,
      mode: "all",
      defaultValues: {
        accountNumber: "",
        description: "",
        contract: null,
        endDate: dayjs(),
        startDate: dayjs(),
        referenceId: "",
        title: "",
        usdAmount: "" as unknown as number,
      },
    });

  const values = useWatch<AdhocInvoiceFormValues>({ control });

  const confirmModal = useConfirmationModal<ConfirmAdhocState>();

  const onSubmit = handleSubmit(
    ({
      createAnother,
      usdAmount,
      contract,
      endDate,
      startDate,
      description,
      title,
      referenceId,
      accountNumber,
    }) => {
      if (!contract) {
        return;
      }
      const charge: CreateAdhocInvoiceInput = {
        jwt,
        amount: usdAmount,
        contract_shared_uuid: contract.uuid,
        end_time: (endDate as Dayjs).toISOString(),
        begin_time: (startDate as Dayjs).toISOString(),
        reference_id_po_number: referenceId,
        general_ledger_account_number: accountNumber,
        header_title: title,
        description,
      };
      confirmModal.onOpen({
        acceptLabel: "Send invoice",
        title: "Please review the information",
        charge,
        contract,
        onConfirm: () => {
          if (!charge) {
            return;
          }
          confirmModal.onClose();
          createCharge(charge, {
            onSuccess: () => {
              toast.success("Invoice created successfully.");
              onSuccess(charge);

              if (createAnother) {
                return reset({
                  contract,
                  startDate,
                  endDate,
                  createAnother: true,
                });
              }

              onClose();
            },
          });
        },
      });
    },
  );

  return (
    <>
      <Modal
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        title="Create new ad hoc invoice for contract"
        preventCloseOnBackdropClick
        {...props}
        actions={
          <ButtonRow width="100%">
            <FormButton
              variant="text"
              color="error"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </FormButton>
            <CreateAnotherField control={control} />
            <Box flexGrow={1} ml={3} display="flex">
              <FormSubmit
                disabled={!formState.isDirty}
                isLoading={isPending}
                form="create-adhoc-invoice-form"
                fullWidth
              />
            </Box>
          </ButtonRow>
        }
      >
        <Form id="create-adhoc-invoice-form" onSubmit={onSubmit}>
          <FormStep>
            <ContractSearchField
              control={control}
              size="small"
              header="Choose a contract"
              placeholder="Select here"
              name="contract"
              fullWidth
            />

            <InputField
              size="small"
              header="Header/Title"
              required
              fullWidth
              control={control}
              name="title"
            />
            <ButtonRow>
              <DateField
                header="Service Date(s)"
                size="small"
                label="Start date"
                fullWidth
                maxDate={isDayjs(values.endDate) ? values.endDate : undefined}
                control={control}
                name="startDate"
                onChange={(value) => {
                  if (value && !hasSetEndDate) {
                    setValue("endDate", value);
                    trigger("endDate");
                  }
                }}
              />
              <DateField
                fullWidth
                header="&nbsp;"
                size="small"
                minDate={isDayjs(values.startDate) ? values.startDate : undefined}
                label="End date"
                onChange={() => {
                  setHasSetEndDate(true);
                }}
                control={control}
                name="endDate"
              />
            </ButtonRow>
            <NumericField
              header="Amount"
              size="small"
              fullWidth
              autoComplete="off"
              required
              slotProps={{ input: { endAdornment: "$" } }}
              control={control}
              name="usdAmount"
            />
            <ButtonRow direction={{ sm: "row" }}>
              <InputField
                size="small"
                header="Reference ID/PO No."
                autoComplete="off"
                fullWidth
                control={control}
                name="referenceId"
              />
              <InputField
                size="small"
                header="General Ledger Account Number"
                fullWidth
                control={control}
                name="accountNumber"
              />
            </ButtonRow>
            <InputField
              size="small"
              header="Description"
              fullWidth
              control={control}
              name="description"
            />
          </FormStep>
        </Form>
      </Modal>
      {confirmModal.open && <ConfirmAdhocModal {...confirmModal} />}
    </>
  );
};
