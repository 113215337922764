import {
  DataGridPage,
  BaseDataGrid,
  IUserGroupUser,
  useUsers,
  useBreakpoint,
  useModal,
  useAccount,
  ActionButton,
} from "@synota-io/synota-shared-ui";
import { GridColDef, GridColumnVisibilityModel, useGridApiRef } from "@mui/x-data-grid";
import { UserEmailCell } from "../tables/userGroup/UserEmailCell";
import { UserRoleCell } from "../tables/userGroup/UserRoleCell";
import { UserActionsCell } from "../tables/userGroup/UserActionsCell";
import { useEffect, useState } from "react";
import { UserStatusCell } from "../tables/userGroup/UserStatusCell";
import { DASHBOARD_URL } from "../../../utils/environment";
import { GroupAdd } from "@mui/icons-material";
import { InviteUserFormModal } from "../../settings/modals/InviteUserFormModal";

const usersColDef: GridColDef<IUserGroupUser>[] = [
  {
    field: "role",
    headerName: "Role",
    renderCell: UserRoleCell,
    width: 140,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: UserStatusCell,
    width: 140,
  },
  {
    field: "email",
    headerName: "Email",
    renderCell: UserEmailCell,
    flex: 1,
    width: 220,
    minWidth: 120,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
];

export const UserGroupPage = () => {
  const apiRef = useGridApiRef();
  const isTablet = useBreakpoint("md");
  const inviteModal = useModal();

  const { isAdmin } = useAccount();
  const { users, isFetching, refetch } = useUsers();

  const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>({});
  useEffect(() => {
    setColumnVisibility({
      description: isTablet,
    });
  }, [isTablet]);

  const columns: GridColDef<IUserGroupUser>[] = isAdmin
    ? [
        ...usersColDef,
        {
          field: "actions",
          headerName: "Actions",
          renderCell: ({ row }) => {
            return <UserActionsCell row={row} onMutationSuccess={refetch} />;
          },
          type: "actions",
          width: 180,
          minWidth: 120,
        },
      ]
    : usersColDef;

  return (
    <DataGridPage
      title="Users"
      actions={
        DASHBOARD_URL &&
        isAdmin && (
          <ActionButton
            color="primary"
            title="Invite New User"
            size="small"
            onClick={() => inviteModal.onOpen()}
            isLoading={isFetching}
          >
            <GroupAdd fontSize="small" />
          </ActionButton>
        )
      }
    >
      <BaseDataGrid
        apiRef={apiRef}
        getRowId={(row) => row.email}
        columnVisibilityModel={columnVisibility}
        onColumnVisibilityModelChange={setColumnVisibility}
        loading={isFetching}
        columns={columns}
        rows={users}
      />

      {inviteModal.open ? <InviteUserFormModal {...inviteModal} onSuccess={refetch} /> : null}
    </DataGridPage>
  );
};
