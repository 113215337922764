import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Alert, AlertTitle, Typography } from "@mui/material";
import { Done } from "@mui/icons-material";
import {
  Form,
  FormStep,
  FormSubmit,
  InputField,
  useAccount,
  useCreateReceiveAddress,
  useDefaultError,
  ContractSearchField,
  ContractSearchOption,
  CopyTextButton,
} from "@synota-io/synota-shared-ui";

interface ContractIdFilterValues {
  contract: ContractSearchOption;
  nameOfAddress: string;
  sourceOfFunds: string;
}

const schema = yup
  .object({
    contract: yup
      .object({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required("You must select a contract"),
    nameOfAddress: yup.string().required("You must set a name for the address"),
    sourceOfFunds: yup.string().required("You must set the source of funds"),
  })
  .required();

export const GenerateAddressForm = () => {
  const { jwt, isAdmin } = useAccount();
  const [receiveAddress, setReceiveAddress] = useState<string>("");

  const { control, handleSubmit, formState } = useForm<ContractIdFilterValues>({
    resolver: yupResolver(schema as any),
    defaultValues: {
      contract: null,
      nameOfAddress: "",
      sourceOfFunds: "Bitcoin Mining",
    },
    mode: "all",
  });

  const { createAddress, isPending, error } = useCreateReceiveAddress();

  useDefaultError(error, "There was a problem retrieving the address, please try again.");

  const onSubmit = (values: ContractIdFilterValues) => {
    if (!jwt || !values.contract?.value || !values.nameOfAddress) {
      return;
    }
    setReceiveAddress("");
    createAddress(
      {
        contract_shared_uuid: values.contract.value,
        name: values.nameOfAddress,
        jwt,
        source_of_funds: values.sourceOfFunds,
      },
      {
        onSuccess: ({ data }) => {
          setReceiveAddress(data.bitcoin_address);
        },
      },
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6">
        Generate a new onchain address to deposit funds onto node
      </Typography>
      <FormStep>
        <ContractSearchField
          readOnly={!isAdmin}
          control={control}
          header="Choose a contract"
          placeholder="Select here"
          name="contract"
          fullWidth
        />
        <InputField
          readOnly={!isAdmin}
          control={control}
          name="nameOfAddress"
          fullWidth
          header="Name of Address"
        />
      </FormStep>
      <FormSubmit
        isLoading={isPending}
        endIcon={!isPending && receiveAddress ? <Done /> : null}
        disabled={!formState.isDirty || !isAdmin}
      >
        Click here to generate
      </FormSubmit>
      {receiveAddress ? (
        <Alert
          severity="success"
          action={<CopyTextButton title="Copy address" text={receiveAddress} />}
        >
          <AlertTitle>Generated address:</AlertTitle>
          <Typography variant="h6" color="text.primary" gutterBottom>
            {receiveAddress}
          </Typography>
        </Alert>
      ) : null}
    </Form>
  );
};
