import { Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { BadgeTypography, IAdhocInvoice } from "@synota-io/synota-shared-ui";
import { CHARGE_STATUS_COLOR_MAP } from "../constants";

export const InvoiceStatusCell = ({ row }: Pick<GridRenderCellParams<IAdhocInvoice>, "row">) => {
  return (
    <Tooltip
      color="primary"
      title={row.rejection_reason ? `Rejected: ${row.rejection_reason}` : ""}
      placement="left"
    >
      <span>
        <BadgeTypography
          title={row.rejection_reason ? "" : row.status}
          width="100%"
          variant="subtitle3"
          status={CHARGE_STATUS_COLOR_MAP[row.status]}
        >
          {row.status}
        </BadgeTypography>
      </span>
    </Tooltip>
  );
};
