import {
  formatDate,
  useReportFiltersForm,
  DataGridPage,
  useGridServerPagination,
  BaseDataGrid,
  IChangeLogItem,
  formatDateTime,
  useChangeLog,
  useDefaultError,
  ActionButton,
  useReportCsv,
  REPORT_ENDPOINTS,
  ButtonRow,
} from "@synota-io/synota-shared-ui";
import { useState } from "react";
import { GridColDef, GridPaginationModel, useGridApiRef } from "@mui/x-data-grid";
import { ReportFiltersToolbarLinked } from "../../../shared/reports/ReportFiltersToolbarLinked";
import { ContractCell } from "../../charges/cells/ContractCell";
import { BackToContractButton } from "../../contracts/components/BackToContractButton";
import { Download } from "@mui/icons-material";

const defaultDates = {
  startDate: null,
  endDate: null,
};

const columns: GridColDef<IChangeLogItem>[] = [
  {
    field: "contract_name",
    headerName: "Contract",
    renderCell: ({ row }) => <ContractCell row={row} />,
    flex: 1,
    width: 220,
    minWidth: 120,
  },
  {
    field: "date_changed",
    type: "number",
    headerName: "Date of Change (UTC)",
    valueFormatter: (value) => formatDateTime(value),
    flex: 1,
    width: 140,
    minWidth: 90,
  },
  {
    field: "effective_date",
    type: "number",
    headerName: "Effective Date",
    valueFormatter: (value) => formatDate(value),
    flex: 1,
    width: 140,
    minWidth: 90,
  },
  {
    field: "previous_value",
    headerName: "Previous value",
    flex: 1,
    width: 140,
    minWidth: 90,
  },
  {
    field: "new_value",
    headerName: "New value",
    flex: 1,
    width: 140,
    minWidth: 90,
  },
  {
    field: "action_description",
    headerName: "Description",
    flex: 1,
    width: 140,
    minWidth: 90,
  },
  {
    field: "changed_by",
    headerName: "Changed by",
    flex: 1,
    width: 140,
    minWidth: 90,
    align: "right",
    headerAlign: "right",
  },
];

export const ChangeLogPage = () => {
  const apiRef = useGridApiRef();

  const [paginationModel, onPaginationModelChange] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 20,
  });

  const {
    form: { control },
    values,
    input,
    getFilename,
  } = useReportFiltersForm({
    defaultDates,
    report: REPORT_ENDPOINTS.CHANGELOG,
  });

  const { items, hasNextPage, isFetching, error } = useChangeLog({
    ...input,
    pagination_i: paginationModel.page,
  });

  useDefaultError(error);

  const paginationProps = useGridServerPagination({
    hasNextPage,
    currentPageRowCount: items.length,
    paginationModel,
    onPaginationModelChange,
  });

  const {
    download,
    error: csvError,
    isFetching: isFetchingCsv,
  } = useReportCsv({ input, filename: getFilename() });

  return (
    <DataGridPage
      title="Changelog"
      recalculateHeightDeps={values}
      actions={
        <ButtonRow>
          <BackToContractButton {...values.contract} />
          <ActionButton
            isLoading={isFetchingCsv}
            disabled={Boolean(csvError || !items?.length)}
            title="Download as CSV"
            color="primary"
            size="small"
            onClick={() => download()}
          >
            <Download />
          </ActionButton>
        </ButtonRow>
      }
      toolbar={<ReportFiltersToolbarLinked control={control} />}
    >
      <BaseDataGrid
        key={paginationModel.page}
        apiRef={apiRef}
        loading={isFetching}
        columns={columns}
        rows={items}
        {...paginationProps}
      />
    </DataGridPage>
  );
};
