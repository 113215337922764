import { IAdhocInvoice } from "@synota-io/synota-shared-ui";
import { ReactNode } from "react";
import {
  CancelRounded,
  CheckCircleRounded,
  CloseRounded,
  VerifiedRounded,
  WatchLaterRounded,
} from "@mui/icons-material";

export const CHARGE_STATUS_COLOR_MAP: Record<
  IAdhocInvoice["status"],
  "warning" | "info" | "success" | "error"
> = {
  "Pending Approval": "warning",
  "Approved, Pending Payment": "info",
  Approved: "info",
  Paid: "success",
  Rejected: "error",
  Voided: "error",
  Pending: "info",
};

export const CHARGE_PAYMENT_STATUS_COLOR_MAP: Record<
  NonNullable<IAdhocInvoice["detailed_status"]>,
  "warning" | "info" | "success" | "error"
> = {
  new: "warning",
  initiated: "info",
  failed: "error",
  paid: "success",
  credit: "success",
};

export const CHARGE_PAYMENT_STATUS_ICON_MAP: Record<
  NonNullable<IAdhocInvoice["detailed_status"]>,
  ReactNode
> = {
  new: <WatchLaterRounded fontSize="small" />,
  initiated: <WatchLaterRounded fontSize="small" />,
  failed: <CloseRounded fontSize="small" />,
  paid: <VerifiedRounded fontSize="small" />,
  credit: <VerifiedRounded fontSize="small" />,
};

export const CHARGE_STATUS_ICON_MAP: Record<IAdhocInvoice["status"], ReactNode> = {
  "Pending Approval": <WatchLaterRounded fontSize="small" />,
  "Approved, Pending Payment": <WatchLaterRounded fontSize="small" />,
  Approved: <CheckCircleRounded fontSize="small" />,
  Pending: <CheckCircleRounded fontSize="small" />,
  Paid: <VerifiedRounded fontSize="small" />,
  Rejected: <CancelRounded fontSize="small" />,
  Voided: <CloseRounded fontSize="small" />,
};
