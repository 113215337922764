import { Stack, Table, TableBody, TableContainer, Typography } from "@mui/material";

import {
  BadgeTypography,
  ButtonRow,
  DetailsList,
  DetailsListItem,
  EStrikeOAuthFeature,
  formatDollarAmount,
  FormButton,
  RevealTypography,
  useAccount,
  useAutomaticPayments,
  useCountryStates,
  useCurrencyConverter,
  useCurrentUsdBalance,
  useDisbursementConfiguration,
  useModal,
} from "@synota-io/synota-shared-ui";
import { StrikeFeatureForm } from "./StrikeFeatureForm";
import { DISBURSEMENT_FREQUENCY_OPTIONS } from "./AutomaticDisbursementForm";
import { CurrencyConverterAchFormModal } from "../modals/CurrencyConverterAchFormModal";

interface Props {
  showPayoutDetails: boolean;
}

export const SupplierCurrencyConverterForm = ({ showPayoutDetails }: Props) => {
  const { isAdmin } = useAccount();
  const { description } = useCurrentUsdBalance();

  const {
    isStrike,
    isSdm,
    isBluePenguin,
    configurations,
    isFetching,
    refetch: refetchCurrencyConverter,
  } = useCurrencyConverter();

  const { configuration: disbursementConfiguration, refetch: refetchDisbursement } =
    useDisbursementConfiguration();

  const { refetchPayout } = useAutomaticPayments();

  const onConnection = () => {
    refetchCurrencyConverter();
    refetchDisbursement();
    refetchPayout();
  };

  const { getSelectedOption } = useCountryStates();

  const frequencyOption = DISBURSEMENT_FREQUENCY_OPTIONS.find(
    (o) => o.value === disbursementConfiguration?.payoutWindowInSeconds,
  );

  const bluePenguinModal = useModal();

  if (isBluePenguin) {
    return (
      <Stack spacing={6}>
        <Typography variant="h6">Bank Account</Typography>
        {configurations?.BluePenguinConfiguration ? (
          <DetailsList>
            <DetailsListItem title="ACH Routing Number">
              <RevealTypography hint hintLength={4} disabled={!isAdmin}>
                {configurations.BluePenguinConfiguration.ach_routing_number}
              </RevealTypography>
            </DetailsListItem>

            <DetailsListItem title="ACH Account Number">
              <RevealTypography hint hintLength={4} disabled={!isAdmin}>
                {configurations.BluePenguinConfiguration.ach_account_number}
              </RevealTypography>
            </DetailsListItem>

            <DetailsListItem title="Account Type">
              {configurations.BluePenguinConfiguration.additional_info?.account_type}
            </DetailsListItem>

            <DetailsListItem title="Company Name">
              {configurations.BluePenguinConfiguration.additional_info?.company_name}
            </DetailsListItem>

            <DetailsListItem title="City">
              {configurations.BluePenguinConfiguration.additional_info?.city}
            </DetailsListItem>

            <DetailsListItem title="State">
              {
                getSelectedOption(configurations.BluePenguinConfiguration.additional_info?.state)
                  ?.label
              }
            </DetailsListItem>

            <DetailsListItem title="Country">
              {configurations.BluePenguinConfiguration.additional_info?.country}
            </DetailsListItem>

            <DetailsListItem title="Zip Code">
              {configurations.BluePenguinConfiguration.additional_info?.postal_code}
            </DetailsListItem>

            <DetailsListItem title="Address">
              {configurations.BluePenguinConfiguration.additional_info?.address}
            </DetailsListItem>
          </DetailsList>
        ) : null}
        {isAdmin ? (
          <ButtonRow>
            <FormButton
              isLoading={isFetching}
              type="button"
              variant="contained"
              size="small"
              onClick={() => bluePenguinModal.onOpen()}
            >
              Update Bank Account
            </FormButton>
          </ButtonRow>
        ) : null}
        {bluePenguinModal.open && <CurrencyConverterAchFormModal {...bluePenguinModal} />}
      </Stack>
    );
  }

  return (
    <Stack spacing={6}>
      <Typography variant="h6">Currency Conversion</Typography>
      {isStrike ? (
        <StrikeFeatureForm
          features={[
            EStrikeOAuthFeature.AutomaticPayout,
            EStrikeOAuthFeature.FromStrikePayments,
            EStrikeOAuthFeature.GetBalance,
          ]}
          onConnect={onConnection}
          onDisconnect={onConnection}
        />
      ) : null}
      <TableContainer>
        <Table>
          <TableBody>
            {isSdm && <DetailsListItem title="Provider">SDM</DetailsListItem>}
            {description && isStrike && (
              <DetailsListItem title="Strike Account">{description}</DetailsListItem>
            )}
            {disbursementConfiguration ? (
              <>
                <DetailsListItem title="Automatic Disbursements">
                  {disbursementConfiguration.enabled ? (
                    <BadgeTypography variant="subtitle4" status="success">
                      Enabled
                    </BadgeTypography>
                  ) : (
                    <BadgeTypography variant="subtitle4" status="error">
                      Disabled
                    </BadgeTypography>
                  )}
                </DetailsListItem>
                {disbursementConfiguration.enabled ? (
                  <>
                    <DetailsListItem title="Disbursement Account">
                      {disbursementConfiguration.accountName}
                    </DetailsListItem>
                    {showPayoutDetails && (
                      <>
                        <DetailsListItem title="Payment Frequency">
                          {disbursementConfiguration.payoutWindowLabel}{" "}
                          {frequencyOption ? (
                            <Typography
                              pl={1}
                              display="inline"
                              fontSize="small"
                              fontFamily="monospace"
                              color="text.secondary"
                            >
                              ({frequencyOption.caption})
                            </Typography>
                          ) : null}
                        </DetailsListItem>
                        <DetailsListItem title="Minimum Payout">
                          {formatDollarAmount(disbursementConfiguration.minPayout)}
                        </DetailsListItem>
                      </>
                    )}
                  </>
                ) : null}
              </>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
