import { Box, CardProps, CircularProgress, Stack, Typography } from "@mui/material";
import {
  DATE_FORMAT_DEFAULT,
  Dayjs,
  DetailsList,
  DetailsListItem,
  DetailsListItemProps,
  Form,
  IContractSummary,
  NumericField,
  TextButton,
  dayjs,
  formatDollarAmount,
  isDayjs,
  usePaymentRequests,
  useSettlementSummary,
  useStatementDataMetrics,
} from "@synota-io/synota-shared-ui";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";

interface Props extends CardProps {
  contract: IContractSummary;
}

interface ExposureFilterValues {
  contractId: { label: string; value: string } | null;
  lastDateWithInvoice: Dayjs;
  daysOfReadEarlyOffset: number;
  securityAmount: number;
  sevenDaysInvoicedAverage: number;
}

const ListItem = ({ children, value, ...props }: DetailsListItemProps & { value?: number }) => {
  const content =
    value && Number(value) < 0 ? (
      <Typography variant="inherit" color="error">
        ({children})
      </Typography>
    ) : (
      <Typography variant="inherit" color={props.color}>
        {children}
      </Typography>
    );
  return <DetailsListItem {...props}>{content}</DetailsListItem>;
};

export const ExposureCalculatorCard = ({ contract }: Props) => {
  const today = dayjs().startOf("day");

  const { control, setValue } = useForm<ExposureFilterValues>({
    defaultValues: {
      contractId: { value: String(contract.uuid), label: contract.name },
      lastDateWithInvoice: today,
      daysOfReadEarlyOffset: 13,
      sevenDaysInvoicedAverage: 4000,
      securityAmount: 175_000,
    },
  });

  const values = useWatch<ExposureFilterValues>({ control });

  const { mtd, isLoading } = useStatementDataMetrics({ contract });

  const { summary, isFetching: isFetchingAvg } = useSettlementSummary({
    contractUuid: contract.uuid,
    itemsPerPage: 7,
    page: 0,
  });

  const lastSettlementItem = summary[summary.length - 1];

  const calculatedSevenDayAvgInvoiceAmount = summary
    .map((i) => i.invoiceAmount)
    .reduce((acc, amount) => (acc += amount), 0);

  const defaultSevenDaysInvoiceAverage = Number(
    (calculatedSevenDayAvgInvoiceAmount / 7).toFixed(2),
  );

  useEffect(() => {
    if (lastSettlementItem && defaultSevenDaysInvoiceAverage) {
      setValue("sevenDaysInvoicedAverage", defaultSevenDaysInvoiceAverage);
      setValue("lastDateWithInvoice", dayjs(lastSettlementItem.date));
    }
  }, [defaultSevenDaysInvoiceAverage, lastSettlementItem, setValue]);

  const lastSettlementDate = isDayjs(values.lastDateWithInvoice)
    ? values.lastDateWithInvoice
    : today;

  const { currentCycle, nextCycle } = exposureCalculation(
    lastSettlementDate,
    Number(values.sevenDaysInvoicedAverage),
    Number(values.daysOfReadEarlyOffset),
    Number(values.securityAmount),
    Number(mtd.statement?.currentAmountOutstanding),
  );

  const { paymentRequests, isFetching: isFetchingPrs } = usePaymentRequests({
    include_quotes: true,
    contract_uuid: contract.uuid,
    start_time: currentCycle.putbackCycleStart.toISOString(),
    end_time: today.toISOString(),
  });

  const totalInvoicedOnCycle = paymentRequests
    .map((pr) => pr.amountInvoiced)
    .reduce((acc, amount) => (acc += amount), 0);

  const totalPaidOnCycle = paymentRequests
    .map((pr) => pr.cashReceived)
    .reduce((acc, amount) => (acc += amount), 0);

  const isFetching = isFetchingAvg || isFetchingPrs || isLoading;

  return (
    <Stack direction="column" spacing={6}>
      <Form flexGrow={1} spacing={5}>
        <Stack direction="row" width="100%" gap={6} flexWrap={{ xs: "wrap", sm: "nowrap" }}>
          <NumericField
            size="small"
            fullWidth
            header="Collateral/LC Amount"
            control={control}
            name="securityAmount"
            autoComplete="off"
            slotProps={{
              input: { endAdornment: "$" },
            }}
          />
          <NumericField
            fullWidth
            header="Putback Days Prior to Billing Cycle"
            size="small"
            control={control}
            name="daysOfReadEarlyOffset"
            slotProps={{
              input: { endAdornment: "Days" },
            }}
          />
        </Stack>

        <Stack direction="row" width="100%" gap={6}>
          <Stack width="100%">
            <Typography sx={{ display: "flex", gap: 4 }} mb={4} variant="subtitle1">
              Average Daily Invoice
              <Box flexGrow={1} />
              <TextButton
                color="secondary"
                title="Use last 7 days invoiced amount average"
                onClick={() => setValue("sevenDaysInvoicedAverage", defaultSevenDaysInvoiceAverage)}
              >
                (Last seven days average: {formatDollarAmount(defaultSevenDaysInvoiceAverage)})
              </TextButton>
            </Typography>
            <NumericField
              fullWidth
              size="small"
              control={control}
              name="sevenDaysInvoicedAverage"
              slotProps={{ input: { endAdornment: "$" } }}
            />
          </Stack>
        </Stack>
      </Form>

      {isFetching ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Stack gap={2}>
          <DetailsList sx={{ flexGrow: 1 }}>
            <Typography mb={2} fontSize="medium" variant="subtitle1" color="primary">
              Current Cycle Exposure
            </Typography>

            <ListItem title="Putback Cycle Start Date">
              {currentCycle.putbackCycleStart.format(DATE_FORMAT_DEFAULT)}
            </ListItem>

            <ListItem title="Billing Cycle Start Date">
              {currentCycle.cycleStart.format(DATE_FORMAT_DEFAULT)}
            </ListItem>

            <ListItem title="Billing Cycle End Date">
              {currentCycle.cycleEnd.format(DATE_FORMAT_DEFAULT)}
            </ListItem>

            <ListItem title="Putback Cycle Total Days">{currentCycle.putbackDays} days</ListItem>

            <ListItem title="Last Operating Date Synota Invoiced For">
              {lastSettlementDate.format(DATE_FORMAT_DEFAULT)}
            </ListItem>

            <ListItem title="Remaining Days in Putback Cycle from last invoice">
              {currentCycle.remainingDays} days
            </ListItem>

            <ListItem
              title="Total Amount Invoiced Since Putback Cycle Start Date"
              value={totalInvoicedOnCycle}
            >
              {formatDollarAmount(totalInvoicedOnCycle)}
            </ListItem>

            <ListItem title="Total Payments since Putback Cycle Start Date">
              {formatDollarAmount(totalPaidOnCycle)}
            </ListItem>

            <ListItem title="Current AR on Synota for this Contract">
              {formatDollarAmount(mtd.statement?.currentAmountOutstanding || 0)}
            </ListItem>

            <ListItem title="Current Total Putback Cycle AR Exposure" value={currentCycle.exposure}>
              {currentCycle.exposure ? formatDollarAmount(Math.abs(currentCycle.exposure)) : "N/A"}
            </ListItem>

            <ListItem
              title="Collateral/LC Amount less Remaining AR Exposure"
              color="primary"
              value={currentCycle.collateralMinusExposure}
            >
              {currentCycle.collateralMinusExposure
                ? formatDollarAmount(Math.abs(currentCycle.collateralMinusExposure))
                : "N/A"}
            </ListItem>
          </DetailsList>
          <DetailsList sx={{ flexGrow: 1 }}>
            <Typography mb={2} fontSize="medium" variant="subtitle1" color="primary">
              Next Cycle Exposure
            </Typography>

            <ListItem title="Putback Cycle Start Date">
              {nextCycle.putbackCycleStart.format(DATE_FORMAT_DEFAULT)}
            </ListItem>

            <ListItem title="Billing Cycle Start Date">
              {nextCycle.cycleStart.format(DATE_FORMAT_DEFAULT)}
            </ListItem>

            <ListItem title="Billing Cycle End Date">
              {nextCycle.cycleEnd.format(DATE_FORMAT_DEFAULT)}
            </ListItem>

            <ListItem title="Next Putback Cycle Exposure" value={nextCycle.exposure}>
              {nextCycle.exposure ? formatDollarAmount(Math.abs(nextCycle.exposure)) : "N/A"}
            </ListItem>

            <ListItem title="Current AR on Synota for this Contract">
              {formatDollarAmount(mtd.statement?.currentAmountOutstanding || 0)}
            </ListItem>

            <ListItem
              title="Next Putback Cycle Exposure + Current AR"
              value={nextCycle.exposureWithAR}
            >
              {nextCycle.exposureWithAR
                ? formatDollarAmount(Math.abs(nextCycle.exposureWithAR))
                : "N/A"}
            </ListItem>

            <ListItem
              title="Collateral/LC Amount less Next Putback Exposure"
              value={nextCycle.collateralMinusExposure}
            >
              {nextCycle.collateralMinusExposure
                ? formatDollarAmount(Math.abs(nextCycle.collateralMinusExposure))
                : "N/A"}
            </ListItem>
          </DetailsList>
        </Stack>
      )}
    </Stack>
  );
};

// METER READING WORK SCHEDULE
export const readingDays: Dayjs[] = [
  dayjs("2024-01-19"),
  dayjs("2024-02-19"),
  dayjs("2024-03-20"),
  dayjs("2024-04-19"),
  dayjs("2024-05-20"),
  dayjs("2024-06-19"),
  dayjs("2024-07-19"),
  dayjs("2024-08-20"),
  dayjs("2024-09-20"),
  dayjs("2024-10-22"),
  dayjs("2024-11-20"),
  dayjs("2024-12-19"),

  dayjs("2025-01-21"),
  dayjs("2025-02-20"),
  dayjs("2025-03-21"),
  dayjs("2025-04-21"),
  dayjs("2025-05-20"),
  dayjs("2025-06-19"),
  dayjs("2025-07-21"),
  dayjs("2025-08-20"),
  dayjs("2025-09-19"),
  dayjs("2025-10-21"),
  dayjs("2025-11-20"),
  dayjs("2025-12-20"),

  dayjs("2026-01-22"),
];

// Use the above listing to determine the days used for calculations
export const getCycleDays = (today: Dayjs, priorDaysOffset: number) => {
  const nextReadDay = readingDays.find((readDay) => readDay.isAfter(today)) || readingDays[1];
  const followingReadDay =
    readingDays.find((readDay) => readDay.isAfter(nextReadDay)) || readingDays[2];

  const cycleStart = nextReadDay;
  const cycleEnd = followingReadDay.subtract(1, "day");
  const putbackCycleStart = nextReadDay.subtract(priorDaysOffset, "days");
  const putbackDays = cycleEnd.diff(putbackCycleStart, "days");
  const remainingDays = cycleEnd.startOf("day").diff(today.startOf("day"), "days");

  return {
    cycleStart,
    cycleEnd,
    putbackCycleStart,
    putbackDays,
    remainingDays,
  };
};

export const exposureCalculation = (
  today: Dayjs,
  calculatedSevenDayAvgInvoiceAmount: number,
  daysOfReadEarlyOffset: number,
  securityAmount: number,
  currentAr: number = 0,
) => {
  const currentCycleDays = getCycleDays(today, daysOfReadEarlyOffset);

  const currentExposure =
    (calculatedSevenDayAvgInvoiceAmount * currentCycleDays.remainingDays + currentAr) * -1;

  const currentCycle = {
    ...currentCycleDays,
    exposure: currentExposure,
    collateralMinusExposure: Number(securityAmount) + currentExposure,
  };

  const nextCycleDays = getCycleDays(currentCycleDays.cycleEnd, daysOfReadEarlyOffset);

  const nextExposure =
    calculatedSevenDayAvgInvoiceAmount *
    nextCycleDays.cycleEnd.diff(nextCycleDays.putbackCycleStart, "days") *
    -1;

  const nextCycle = {
    ...nextCycleDays,
    exposure: nextExposure,
    exposureWithAR: nextExposure + currentAr,
    collateralMinusExposure: nextExposure + currentAr + Number(securityAmount),
  };

  return {
    currentCycle,
    nextCycle,
  };
};
