import {
  BalanceBox,
  EStrikeOAuthFeature,
  StatCard,
  useCurrentUsdBalance,
  useStrikeConfiguration,
} from "@synota-io/synota-shared-ui";
import { StrikeLogo } from "../../../shared/media/StrikeLogo";
import { CardContent, Stack } from "@mui/material";
import { CurrentContractsSummary } from "./CurrentContractsSummary";
import { Link } from "react-router-dom";
import { SETTINGS_PATH } from "../../../paths";

export const CurrentUsdBalanceSummary = () => {
  const { balances, description } = useCurrentUsdBalance();

  const { hasAllFeatures: isStrike } = useStrikeConfiguration({
    features: [EStrikeOAuthFeature.GetBalance],
  });

  return (
    <Stack direction="row" gap={6} flexGrow={1} flexWrap="wrap">
      {balances && Object.values(balances).length ? (
        <StatCard>
          <CardContent>
            <BalanceBox
              component={Link}
              to={SETTINGS_PATH}
              title="Go to Settings"
              alwaysShow={["BTC", "USD"]}
              header="Balance"
              slotProps={{ header: { action: isStrike ? <StrikeLogo /> : null } }}
              balances={balances}
              description={description}
            />
          </CardContent>
        </StatCard>
      ) : null}
      <Stack flexGrow={1} maxWidth="50%">
        <CurrentContractsSummary />
      </Stack>
    </Stack>
  );
};
