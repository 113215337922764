import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import {
  ButtonRow,
  ContractSearchField,
  ContractSearchOption,
  DocumentResponse,
  DocumentUploadInput,
  ErrorOnlyField,
  Form,
  FormStep,
  FormSubmit,
  InputField,
  Modal,
  ModalProps,
  UNEXPECTED_ERROR,
  UploadFileField,
  useAccount,
  useContractsSummary,
  useDocumentsMutations,
  useToast,
} from "@synota-io/synota-shared-ui";
import { MutateOptions } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { AxiosResponse, AxiosError } from "axios";
import * as yup from "yup";
import { useEffect } from "react";

export type UploadDocumentModalState = { uuid?: string; file?: File };

interface UploadDocumentFormValues {
  contract: ContractSearchOption;
  filename: string;
  original_filename: string;
  file: File | "";
}

const schema = yup.object({
  original_filename: yup.string().required(),
  filename: yup.string().nullable(),
  file: yup.string().required("A PDF file is required"),
  contract: yup
    .object({
      value: yup.string().required("Invalid contract"),
      label: yup.string(),
    })
    .required("Contract is required"),
});

interface Props {
  onSuccess: MutateOptions<
    AxiosResponse<DocumentResponse, any>,
    AxiosError,
    DocumentUploadInput,
    unknown
  >["onSuccess"];
}

export const UploadDocumentFormModal = ({
  onSuccess,
  ...props
}: ModalProps<UploadDocumentModalState> & Props) => {
  const { jwt } = useAccount();
  const { find } = useContractsSummary();

  const toast = useToast();
  const findContract = find(props.state?.uuid);

  const form = useForm<UploadDocumentFormValues>({
    mode: "onSubmit",
    resolver: yupResolver(schema) as any,
    defaultValues: { contract: null, file: "" },
    values: findContract
      ? {
          contract: { value: findContract.uuid, label: findContract.name, ...findContract },
          filename: "",
          file: "",
          original_filename: "",
        }
      : undefined,
  });

  const { upload, isPendingUpload } = useDocumentsMutations();

  const values = form.watch();

  const onSubmit = form.handleSubmit(({ contract, filename, original_filename }) => {
    if (!contract || !values.file) {
      return;
    }

    let filename_input = filename || original_filename;

    if (filename_input?.split(".pdf").length === 1) {
      filename_input += ".pdf";
    }

    upload(
      {
        jwt,
        contract_shared_uuid: contract.value,
        file: values.file,
        filename: filename_input,
      },
      {
        onSuccess,
        onError: (error) => {
          const message = error.response?.data as string;
          if (error.status === 400 && message) {
            form.setError("file", { message });
          } else {
            toast.error(UNEXPECTED_ERROR);
          }
        },
      },
    );
  });

  useEffect(() => {
    if (props.state?.file && !form.getFieldState("file").isTouched) {
      form.setValue("file", props.state.file, { shouldTouch: true, shouldDirty: true });
      form.setValue("original_filename", props.state.file.name, {
        shouldTouch: true,
        shouldDirty: true,
      });
      form.trigger(["file", "original_filename"]);
    }
  }, [form, props.state?.file]);

  return (
    <Modal
      title="Upload a document"
      fullWidth
      {...props}
      actions={
        <ButtonRow width="100%">
          <Button disabled={isPendingUpload} onClick={() => props.onClose()} color="error">
            Close
          </Button>
          <FormSubmit isLoading={isPendingUpload} form="upload-document-form" fullWidth>
            Submit
          </FormSubmit>
        </ButtonRow>
      }
    >
      <Form onSubmit={onSubmit} id="upload-document-form">
        <FormStep>
          <ContractSearchField
            required
            autoFocus={!findContract}
            header="Contract"
            placeholder="Select here"
            fullWidth
            control={form.control}
            name="contract"
          />
          <UploadFileField
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) {
                form.setValue("original_filename", file.name);
                form.trigger("original_filename");
              }
            }}
            accept="application/pdf"
            placeholder="Upload a PDF"
            caption="Max. 25MB"
            maxSizeMegabytes={25}
            header="Document"
            fullWidth
            control={form.control}
            name="file"
          />
          <InputField
            autoFocus={!!findContract}
            header="Filename"
            autoComplete="off"
            fullWidth
            control={form.control}
            slotProps={{ input: { endAdornment: ".pdf" } }}
            name="filename"
          />
          <ErrorOnlyField control={form.control} name="root" />
        </FormStep>
      </Form>
    </Modal>
  );
};
