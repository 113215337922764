import {
  Typography,
  Box,
  Divider,
  ListItem,
  ListItemText,
  MenuList,
  Stack,
  SvgIconProps,
  BadgeProps,
} from "@mui/material";
import {
  DASHBOARD_PATH,
  EXPLORER_SETTLEMENTS_PATH,
  EXPLORER_REPORTS_PATH,
  EXPLORER_DEPOSIT_HISTORY_PATH,
  ADHOC_INVOICES_PATH,
  CONTRACTS_PATH,
  CREDITS_PATH,
  EXPLORER_DOCUMENTS_PATH,
} from "../../paths";
import {
  BitcoinPriceTicker,
  Link,
  MenuItem,
  useAccount,
  useLayout,
} from "@synota-io/synota-shared-ui";
import {
  AccountBalanceOutlined,
  DashboardOutlined,
  DescriptionOutlined,
  ElectricBoltOutlined,
  FindInPageOutlined,
  PaymentsOutlined,
  RequestQuoteOutlined,
  StackedBarChartOutlined,
} from "@mui/icons-material";
import { CONSUMER_TOS_URL, DEBUG_ENABLED, FEATURE_ENABLE_DOCUMENTS } from "../../utils/environment";
import { FC, useMemo } from "react";

export type AppMenuItem = {
  title: string;
  path: string;
  Icon: FC<SvgIconProps>;
  badgeColor?: BadgeProps["color"];
  exact?: boolean;
};

export interface EnabledAppMenuItem extends AppMenuItem {
  disabled?: boolean;
  badge?: boolean | number;
}

const APP_MENU_ITEMS: Record<string, AppMenuItem> = {
  dashboard: {
    title: "Dashboard",
    path: DASHBOARD_PATH,
    Icon: StackedBarChartOutlined,
    exact: true,
  },
  contracts: {
    title: "Contracts",
    path: CONTRACTS_PATH,
    Icon: DashboardOutlined,
  },
  settlements: {
    title: "Settlements",
    path: EXPLORER_SETTLEMENTS_PATH,
    Icon: ElectricBoltOutlined,
  },
  deposits: {
    title: "Deposits",
    path: EXPLORER_DEPOSIT_HISTORY_PATH,
    Icon: AccountBalanceOutlined,
  },
  reports: {
    title: "Reports",
    path: EXPLORER_REPORTS_PATH,
    Icon: FindInPageOutlined,
  },
  adhoc: {
    Icon: RequestQuoteOutlined,
    title: "Ad Hoc Invoices",
    path: ADHOC_INVOICES_PATH,
    badgeColor: "warning",
  },
  credits: {
    Icon: PaymentsOutlined,
    title: "Credits",
    path: CREDITS_PATH,
  },
  documents: {
    Icon: DescriptionOutlined,
    title: "Documents",
    path: EXPLORER_DOCUMENTS_PATH,
    badgeColor: "warning",
  },
};

export const useAppMenu = () => {
  const { hasLightningNode, isConsumer, unapprovedAdhocInvoices, supportsAdhocInvoices } =
    useAccount();

  const menuItems: EnabledAppMenuItem[] = useMemo(
    () => [
      {
        ...APP_MENU_ITEMS.dashboard,
      },
      {
        ...APP_MENU_ITEMS.contracts,
      },
      {
        ...APP_MENU_ITEMS.settlements,
      },
      {
        ...APP_MENU_ITEMS.deposits,
        disabled: !isConsumer || !hasLightningNode,
      },
      {
        ...APP_MENU_ITEMS.reports,
      },
      {
        ...APP_MENU_ITEMS.adhoc,
        disabled: !supportsAdhocInvoices,
        badge: unapprovedAdhocInvoices,
      },
      {
        ...APP_MENU_ITEMS.credits,
      },
      {
        ...APP_MENU_ITEMS.documents,
        disabled: !FEATURE_ENABLE_DOCUMENTS,
      },
    ],
    [hasLightningNode, isConsumer, supportsAdhocInvoices, unapprovedAdhocInvoices],
  );

  return menuItems;
};

export const AppMenu = () => {
  const { isMobile } = useLayout();
  const { hasLightningNode, isConsumer } = useAccount();

  const menuItems = useAppMenu();

  return (
    <Stack height="100%">
      <MenuList>
        {menuItems
          .filter((item) => !item.disabled)
          .map((item) => (
            <MenuItem key={item.path} {...item} />
          ))}

        {isMobile && isConsumer && hasLightningNode ? (
          <Box pt={8}>
            <Divider />
            <ListItem>
              <ListItemText sx={{ py: 3 }}>
                <BitcoinPriceTicker />
              </ListItemText>
            </ListItem>
          </Box>
        ) : null}
      </MenuList>
      <Box flexGrow={1} />

      <Stack alignItems="center">
        {isConsumer && (
          <Link
            variant="body1"
            color="text.primary"
            href={CONSUMER_TOS_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>
        )}
        {DEBUG_ENABLED ? <Typography>Frontend v{APP_VERSION}</Typography> : null}
      </Stack>
    </Stack>
  );
};
