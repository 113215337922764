import {
  ConfirmationModal,
  FormButton,
  IUserGroupUser,
  useAccount,
  useApiFieldErrors,
  useConfirmationModal,
  useToast,
  useUsersMutations,
} from "@synota-io/synota-shared-ui";
import { Stack } from "@mui/material";
import { Block, DeleteForever, ReplyAll } from "@mui/icons-material";
import { useEffect, useState } from "react";

export const UserActionsCell = ({
  row,
  onMutationSuccess,
}: {
  row: IUserGroupUser;
  onMutationSuccess: () => void;
}) => {
  const toast = useToast();
  const confirmationModal = useConfirmationModal();

  const [sentInvitation, setSentInvitation] = useState(false);

  useEffect(() => {
    if (sentInvitation) {
      const timeout = setTimeout(() => {
        setSentInvitation(false);
      }, 10 * 1000);
      return () => clearTimeout(timeout);
    }
  }, [sentInvitation]);

  const { jwt, email } = useAccount();
  const {
    isPending,
    deletePendingUser,
    deletePendingUserResponse,
    reinviteUser,
    reinviteUserResponse,
    disableUser,
    disableUserResponse,
  } = useUsersMutations();

  useApiFieldErrors(
    deletePendingUserResponse?.data,
    "There was a problem deleting the user, please try again",
  );
  useApiFieldErrors(
    reinviteUserResponse?.data,
    "There was a problem sending the invitation, please try again",
  );
  useApiFieldErrors(
    disableUserResponse?.data,
    "There was a problem deactivating the user, please try again",
  );

  const actionsDisabled = row.isAdmin && row.role !== "pending_backup_admin";

  return (
    <Stack direction="row" gap={2} alignItems="center" height="100%">
      {row.status === "Pending" ? (
        <>
          <FormButton
            tooltip="Resend Invitation"
            isLoading={isPending}
            disabled={sentInvitation || actionsDisabled}
            color="primary"
            disablePadding
            size="small"
            onClick={() => {
              reinviteUser(
                { jwt, user_email: row.email },
                {
                  onSuccess({ data }) {
                    if (data.success) {
                      setSentInvitation(true);
                      onMutationSuccess();
                      toast.success("A new invitation was sent to " + row.email + ".");
                    }
                  },
                },
              );
            }}
          >
            <ReplyAll />
          </FormButton>

          <FormButton
            tooltip="Delete"
            isLoading={isPending}
            disabled={actionsDisabled}
            color="error"
            disablePadding
            size="small"
            onClick={() => {
              confirmationModal.onOpen({
                title: "Delete user?",
                body: row.email,
                acceptLabel: "Delete",
                onConfirm() {
                  deletePendingUser(
                    { jwt, email_to_delete: row.email },
                    {
                      onSuccess({ data }) {
                        if (data.success) {
                          onMutationSuccess();
                          confirmationModal.onClose();
                          toast.success(row.email + " has been deleted.");
                        }
                      },
                    },
                  );
                },
              });
            }}
          >
            <DeleteForever />
          </FormButton>
        </>
      ) : (
        <>
          {!row.disabled && row.email !== email ? (
            <FormButton
              tooltip="Deactivate User"
              isLoading={isPending}
              color="error"
              disablePadding
              size="small"
              onClick={() => {
                confirmationModal.onOpen({
                  title: "Deactivate user?",
                  body: row.email,
                  acceptLabel: "Deactivate",
                  onConfirm() {
                    disableUser(
                      { jwt, email_to_disable: row.email },
                      {
                        onSuccess({ data }) {
                          if (data.success) {
                            onMutationSuccess();
                            confirmationModal.onClose();
                            toast.success(row.email + " has been deactivated.");
                          }
                        },
                      },
                    );
                  },
                });
              }}
            >
              <Block />
            </FormButton>
          ) : null}
        </>
      )}

      <ConfirmationModal isLoading={isPending} {...confirmationModal} />
    </Stack>
  );
};
