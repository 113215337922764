import { ArrowLeft } from "@mui/icons-material";
import { ButtonLink } from "@synota-io/synota-shared-ui";
import { generatePath, matchPath, useLocation } from "react-router-dom";
import { CONTRACT_PATH } from "../../../paths";

export const BackToContractButton = ({
  value,
  uuid,
  label,
}: {
  value?: string;
  uuid?: string;
  label?: string;
}) => {
  const { state } = useLocation();

  const contractId = value || uuid;

  if (!contractId) {
    return null;
  }

  return (
    <ButtonLink
      title={label}
      sx={{ px: 2, mx: 2 }}
      startIcon={<ArrowLeft />}
      size="small"
      variant="text"
      to={generatePath(CONTRACT_PATH, { contractId })}
    >
      {state?.from && matchPath(CONTRACT_PATH, state.from) ? "Back to Contract" : "Go to Contract"}
    </ButtonLink>
  );
};
