import { yupResolver } from "@hookform/resolvers/yup";
import {
  ConfirmationModal,
  Form,
  FormStep,
  InputField,
  UseConfirmationModalProps,
} from "@synota-io/synota-shared-ui";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object({
  reason: yup.string().required("A reason is required to reject this charge"),
});

export type RejectAdhocModalState = {
  onConfirm: ({ reason }: { reason: string }) => void;
};

export const RejectAdhocModal = ({
  ...props
}: UseConfirmationModalProps<RejectAdhocModalState>) => {
  const { control, handleSubmit } = useForm({
    defaultValues: { reason: "" },
    resolver: yupResolver(schema),
  });

  return (
    <ConfirmationModal
      {...props}
      state={{
        title: "Reject invoice?",
        acceptLabel: "Reject Invoice",
        ...props.state,
      }}
    >
      <Form
        id="confirmation-modal"
        onSubmit={handleSubmit((data) => {
          props.onClose();
          props.state.onConfirm(data);
        })}
      >
        <FormStep>
          <InputField fullWidth required control={control} name="reason" header="Reason" />
        </FormStep>
      </Form>
    </ConfirmationModal>
  );
};
