import { Layout } from "@synota-io/synota-shared-ui";
import { useIsFetching } from "@tanstack/react-query";
import { SidebarContent } from "./SidebarContent";
import { TopbarContent } from "./TopbarContent";
import { FEATURE_DATA_NOTICE_BANNER, FEATURE_ENABLE_NOTICE_BANNER } from "../../utils/environment";

export const LayoutContainer = () => {
  const isFetching = useIsFetching();
  return (
    <Layout
      disableScroll
      isFetching={Boolean(isFetching)}
      sidebar={<SidebarContent />}
      topbar={<TopbarContent />}
      banner={FEATURE_ENABLE_NOTICE_BANNER ? FEATURE_DATA_NOTICE_BANNER : undefined}
    />
  );
};
