import { useAccount } from "@synota-io/synota-shared-ui";
import { Navigate, Outlet } from "react-router-dom";
import { LoadingPage } from "../../shared/pages/LoadingPage";
import { Box } from "@mui/material";

export const ChargesOutlet = () => {
  const { email, supportsAdhocInvoices, isFetching, isReady } = useAccount();

  if (
    // Ideally supportsAdhocInvoices should be false when the
    // account is loaded so that we dont need to check for "email"
    !isFetching &&
    isReady &&
    email &&
    !supportsAdhocInvoices
  ) {
    return <Navigate to="/" />;
  }

  return (
    <LoadingPage component={Box}>
      <Outlet />
    </LoadingPage>
  );
};
