import { Button, Stack } from "@mui/material";
import { SETTINGS_PATH } from "../../../paths";
import { useNavigate } from "react-router-dom";
import { Modal, ModalProps } from "@synota-io/synota-shared-ui";

const RequiredAddressModal = ({ open, onClose, ...props }: ModalProps) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="You must set a withdrawal address to continue."
      hideClose
      {...props}
    >
      <Stack spacing={8}>
        <Button variant="contained" onClick={() => navigate(SETTINGS_PATH)}>
          Go to Settings Page
        </Button>
      </Stack>
    </Modal>
  );
};

export { RequiredAddressModal };
