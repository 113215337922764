import { Box, Tooltip } from "@mui/material";
import { SwitchField } from "@synota-io/synota-shared-ui";
import { Control } from "react-hook-form";

export const CreateAnotherField = ({ control }: { control: Control<any, any> }) => {
  return (
    <Tooltip
      placement="left"
      arrow
      title="Enable to add another entry right after submitting this one"
    >
      <Box pt={1}>
        <SwitchField label="Create Another" name="createAnother" control={control} />
      </Box>
    </Tooltip>
  );
};
