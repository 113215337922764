import {
  ButtonLink,
  ButtonLinkProps,
  ButtonRow,
  EContractState,
  IContractSummary,
  useAccount,
  useWindowSize,
} from "@synota-io/synota-shared-ui";
import { generatePath, Link, useMatch, useLocation } from "react-router-dom";
import {
  ADHOC_INVOICES_PATH,
  CONTRACT_EXPOSURE_PATH,
  CONTRACT_PATH,
  CONTRACTS_PATH,
  CREDITS_PATH,
  EXPLORER_CHANGELOG_PATH,
  EXPLORER_DEPOSIT_HISTORY_PATH,
  EXPLORER_DOCUMENTS_PATH,
  EXPLORER_SETTLEMENTS_PATH,
} from "../../../paths";
import { ContractMoreMenu } from "./ContractMoreMenu";
import { useContractActions } from "../hooks/useContractActions";
import { alpha, ButtonProps, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { forwardRef, Ref, useLayoutEffect, useMemo, useRef, useState } from "react";
import { EnabledAppMenuItem, useAppMenu } from "../../../shared/menu/AppMenu";
import { FEATURE_ENABLE_AR_EXPOSURE } from "../../../utils/environment";

const contractSearch = (contract: IContractSummary) => "?contract=" + contract.uuid;
const signContractSearch = (contract: IContractSummary) => "?sign=" + contract.uuid;

interface Props {
  contract: IContractSummary | null;
  maxWidth?: number;
}

interface ContractMenuButton {
  label: string;
  title?: string;
  color?: ButtonProps["color"];
  path: string;
  appMenuItem?: EnabledAppMenuItem;
  hidden?: (contract: IContractSummary, isAdmin?: boolean | null) => boolean;
  search?: (contract: IContractSummary) => string;
}

function getToFromPath(button: ContractMenuButton, contract: IContractSummary) {
  return (
    generatePath(button.path, { contractId: contract?.uuid || null }) +
    (button.search ? button.search(contract) : "")
  );
}

const hiddenUnsigned = (contract: IContractSummary) => contract.status === EContractState.Unsigned;

const buttons: Array<ContractMenuButton> = [
  {
    label: "Dashboard",
    path: CONTRACT_PATH,
  },
  {
    label: "Sign Contract",
    path: CONTRACTS_PATH,
    color: "secondary",
    hidden: (contract, isAdmin) =>
      !isAdmin || contract.status !== EContractState.Unsigned || contract.signedBySelf,
    search: signContractSearch,
  },
  {
    label: "Credits",
    path: CREDITS_PATH,
    hidden: hiddenUnsigned,
    search: contractSearch,
  },
  {
    label: "Ad Hoc",
    title: "Ad Hoc Invoices",
    path: ADHOC_INVOICES_PATH,
    hidden: hiddenUnsigned,
    search: contractSearch,
  },
  {
    label: "Documents",
    path: EXPLORER_DOCUMENTS_PATH,
    hidden: hiddenUnsigned,
    search: contractSearch,
  },
  {
    label: "Settlements",
    path: EXPLORER_SETTLEMENTS_PATH,
    hidden: hiddenUnsigned,
    search: contractSearch,
  },
  {
    label: "Deposits",
    path: EXPLORER_DEPOSIT_HISTORY_PATH,
    hidden: hiddenUnsigned,
    search: contractSearch,
  },
  {
    label: "Exposure",
    title: "A/R Exposure",
    path: CONTRACT_EXPOSURE_PATH,
    hidden: (contract) => hiddenUnsigned(contract) || !FEATURE_ENABLE_AR_EXPOSURE,
  },
  {
    label: "Changelog",
    title: "Changelog",
    path: EXPLORER_CHANGELOG_PATH,
    search: contractSearch,
    hidden: hiddenUnsigned,
  },
];

export const ContractMenu = ({ contract, maxWidth }: Props) => {
  const { pathname } = useLocation();
  const { isAdmin } = useAccount();
  const ref = useRef<HTMLDivElement>(null);
  const dotsRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<Array<HTMLButtonElement | null>>([]);
  const size = useWindowSize();
  const appMenu = useAppMenu();

  const overflownRef = useRef<number[]>([]);
  const [overflownItems, setOverflownItems] = useState<number[]>([]);

  useLayoutEffect(() => {
    overflownRef.current = [];
  }, [size]);

  useLayoutEffect(() => {
    if (overflownRef.current.length) {
      return;
    }

    const width = ref.current?.offsetWidth || 0;
    const dotsWidth = dotsRef.current?.scrollWidth || 0;

    const widths = itemsRef.current.map((i) => i?.scrollWidth || 0);

    if (!maxWidth) return;

    let currentWidth = dotsWidth;
    const visible: number[] = [];

    if (width > maxWidth) {
      for (const [index, elWidth] of widths.entries()) {
        if (!elWidth) continue;
        currentWidth += elWidth;

        visible.push(index);

        const next = widths[index + 1];

        if (!next) {
          break;
        }

        if (currentWidth + 12 + next > maxWidth) {
          break;
        }
      }

      overflownRef.current = Array.from(widths.keys()).filter((k) => !visible.includes(k));
      setOverflownItems(overflownRef.current);
    }
  }, [size, contract, maxWidth]);

  const contractActions = useContractActions({ contract });
  const key = useMemo(() => overflownItems.join("") + pathname, [overflownItems, pathname]);

  const enabledButtons = useMemo(
    () =>
      contract
        ? buttons
            .map((button) => ({
              ...button,
              appMenuItem: appMenu.find((m) => m.path === button.path),
            }))
            .filter(
              (button) => !button.appMenuItem?.disabled && !button.hidden?.(contract, isAdmin),
            )
        : [],
    [appMenu, contract, isAdmin],
  );

  const buttonElements = useMemo(
    () =>
      contract
        ? enabledButtons
            .filter((_button, i) => !overflownItems?.includes(i))
            .reverse()
            .map((button, i) => (
              <ContractMenuButton
                key={button.label}
                ref={(node) => (itemsRef.current[i] = node)}
                title={button.title || button.label}
                path={button.path}
                color={button.color}
                to={getToFromPath(button, contract)}
                state={{ from: pathname }}
              >
                {button.label}
              </ContractMenuButton>
            ))
        : [],
    [contract, enabledButtons, overflownItems, pathname],
  );

  const moreButtonElements = useMemo(
    () =>
      contract
        ? enabledButtons
            .filter((_button, i) => overflownItems?.includes(i))
            .reverse()
            .map((button) => {
              const Icon = button.appMenuItem?.Icon || LinkIcon;
              return {
                action: button.label,
                element: (
                  <MenuItem
                    key={button.label}
                    component={Link}
                    to={getToFromPath(button, contract)}
                    state={{ from: pathname }}
                  >
                    <ListItemIcon>
                      <Icon color="inherit" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{button.title || button.label}</ListItemText>
                  </MenuItem>
                ),
              };
            })
        : [],
    [contract, enabledButtons, overflownItems, pathname],
  );

  if (!contract) {
    return null;
  }

  return (
    <ButtonRow key={key} role="menu" gap={1} ref={ref}>
      {buttonElements}
      <ContractMoreMenu
        ref={dotsRef}
        elements={moreButtonElements}
        actions={[...enabledButtons.map((b) => b.label), ...contractActions]}
        size="small"
        contract={contract}
      />
    </ButtonRow>
  );
};

const ContractMenuButton = forwardRef(function ContractMenuButton(
  { children, path, ...props }: ButtonLinkProps & { path: string },
  ref: Ref<HTMLButtonElement>,
) {
  const isActive = Boolean(useMatch({ path, end: true }));

  return (
    <ButtonLink
      ref={ref}
      role="menuitem"
      size="small"
      sx={(theme) => ({
        whiteSpace: "nowrap",
        bgcolor: isActive ? undefined : alpha(theme.palette.text.primary, 0.04),
        "&:hover": {
          bgcolor: isActive ? undefined : alpha(theme.palette.text.primary, 0.08),
        },
      })}
      aria-selected={isActive}
      {...props}
      color={isActive ? "primary" : props.color || "neutral"}
    >
      {children}
    </ButtonLink>
  );
});
