import { Slide, Box, Stack, Grid2 as Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { CashFlowChart } from "../../dashboard/components/CashFlowChart";
import { MetricsTable } from "../../dashboard/components/MetricsTable";
import { ContractMonthlySummary } from "../reports/ContractMonthlySummary";
import { useAccount, useBreakpoint, useContractDetails } from "@synota-io/synota-shared-ui";
import { MinerManagement } from "../miner-management/MinerManagement";
import { ContractAuditReport } from "../reports/ContractAuditReport";
import { ContractBlocks } from "../details/ContractBlocks";
import { ContractTerms } from "../details/ContractTerms";

export const ContractPage = () => {
  const { isConsumer, isReady } = useAccount();
  const { contractId } = useParams();
  const { contract, isLoading } = useContractDetails({ uuid: contractId });

  const isLargeDesktop = useBreakpoint("xl");

  const { foremanAccess, consumerApprovedSharing } = contract?.minerManagement || {};

  const minerManagementReady = isReady && contract;
  const supplierMinerManagementReady = isConsumer || (foremanAccess && consumerApprovedSharing);

  const showMinerManagement = Boolean(minerManagementReady && supplierMinerManagementReady);

  return (
    <Grid container spacing={6}>
      <Grid size={{ xs: 12, lg: 6 }}>
        <Slide in direction="left">
          <Box width="100%">
            <CashFlowChart contractUuid={contractId} height={isLargeDesktop ? 250 : undefined} />
          </Box>
        </Slide>
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        <Slide in direction="left">
          <Stack gap={6} height={showMinerManagement ? "100%" : undefined}>
            <Box flex="1 1 50%">
              <MetricsTable contract={contract} isLoading={isLoading} />
            </Box>
            {contract && showMinerManagement ? <MinerManagement contract={contract} /> : null}
          </Stack>
        </Slide>
      </Grid>
      <Grid size={{ xs: 12, xl: contract?.hasAuditReport ? 5 : 12 }}>
        <Slide in direction="left">
          <Box>
            <ContractMonthlySummary contract={contract} />
          </Box>
        </Slide>
      </Grid>
      {contract?.hasAuditReport ? (
        <Grid size={{ xs: 12, xl: 7 }}>
          <Slide in direction="left">
            <Box>
              <ContractAuditReport contract={contract} />
            </Box>
          </Slide>
        </Grid>
      ) : null}
      <Grid size={12}>
        <Slide in direction="up">
          <Box>
            <ContractTerms contract={contract} />
          </Box>
        </Slide>
      </Grid>
      <Grid size={12}>
        <Slide in direction="up">
          <Box>
            <ContractBlocks contract={contract} />
          </Box>
        </Slide>
      </Grid>
    </Grid>
  );
};
