import { ReportFiltersToolbar, ReportFiltersToolbarProps } from "@synota-io/synota-shared-ui";
import { useNavigate, generatePath } from "react-router-dom";
import { CONTRACT_PATH } from "../../paths";

export const ReportFiltersToolbarLinked = (props: ReportFiltersToolbarProps) => {
  const navigate = useNavigate();
  return (
    <ReportFiltersToolbar
      onContractOpen={(e, { value }) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(generatePath(CONTRACT_PATH, { contractId: value }));
      }}
      {...props}
    />
  );
};
