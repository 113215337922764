import { Stack, Box, Typography } from "@mui/material";
import {
  formatDollarAmount,
  formatDateTime,
  IContract,
  IStatementData,
  useBitcoinRate,
  useAccount,
} from "@synota-io/synota-shared-ui";

export const MetricsFooter = ({
  contract,
  mode,
  statement,
}: {
  contract?: IContract | null;
  statement: IStatementData | null;
  mode: "A/R" | "A/P";
}) => {
  const { getBitcoin } = useBitcoinRate();
  const { paymentMethod } = useAccount();

  if (!statement) {
    return null;
  }

  return (
    <Stack pt={1} direction={{ xs: "column", sm: "row", lg: "column", xl: "row" }} gap={1}>
      <Stack gap={2}>
        <Stack direction="row" spacing={2} flexWrap="wrap">
          <span>{mode}:</span>
          <span>{formatDollarAmount(statement.currentAmountOutstanding || 0)}</span>
          {mode === "A/P" && !paymentMethod?.isBluePenguin ? (
            <Typography variant="inherit" color="text.secondary">
              ({getBitcoin(statement.currentAmountOutstanding || 0)} BTC)
            </Typography>
          ) : null}
        </Stack>

        {contract ? (
          <Stack direction="row" spacing={2}>
            <span>Credit Balance:</span>
            <span>{formatDollarAmount(Number(contract.creditBalance))}</span>
          </Stack>
        ) : null}
      </Stack>
      <Box flexGrow={1} />
      {contract && statement.lastInvoiceTime ? (
        <Typography
          variant="inherit"
          color="text.secondary"
          display="flex"
          alignItems="end"
          gap={2}
        >
          <span>Last Invoice:</span>
          <span>{formatDateTime(statement.lastInvoiceTime)} UTC</span>
        </Typography>
      ) : null}
    </Stack>
  );
};
